import { appSlice } from 'state/redux/slices/app/appSlice';

export * from 'state/redux/slices/app/appSelectors';

export const { setAudioUrl } = appSlice.actions;
export const { setRecordingInfo } = appSlice.actions;
export const { setIsTrascriptionModal } = appSlice.actions;
export const { setFilters } = appSlice.actions;
export const { setModalFormIsOpen } = appSlice.actions;
export const { setFormData } = appSlice.actions;
export const { setRecordingId } = appSlice.actions;

export const { reducer: app } = appSlice;
