import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import LocalStorageManager from 'services/LocalStorageManager/LocalStorageManager';
import { API_URL } from 'shared/constants';

const cache = new InMemoryCache();

const httpLink = new HttpLink({
    uri: API_URL,
});

const uploadLink = createUploadLink({
    uri: API_URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            'X-Google-Code':
                LocalStorageManager.getValue('O-Auth2-Code') || null,
            'X-Redirect-Url':
                LocalStorageManager.getValue('X-Redirect-Url') || null,
            'X-Session-Token':
                LocalStorageManager.getValue('X-Session-Token') || null,
        },
    });

    return forward(operation);
});

const client = new ApolloClient({
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: ApolloLink.from([authMiddleware, uploadLink, httpLink]),
    name: 'ai-voice-web-app',
});

export default client;
