import styled, { css } from 'styled-components';

export const StatusWrapper = styled.div(
    () => css`
        align-items: flex-start;
        display: flex;
        justify-content: center;
        position: relative;
        width: fit-content;
    `,
);

export const StatusIconWrapper = styled.div`
    filter: drop-shadow(0px 3px 4px rgba(151, 151, 151, 0.33));
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%, -25%);
`;

export const StatusButton = styled.div<{ status: boolean }>(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight }, status }) =>
        css`
            background-color: ${status
                ? 'rgba(0, 196, 185, 0.3)'
                : 'rgba(244, 89, 135, 0.59)'};
            align-items: center;
            border-radius: 25px;
            color: ${status ? colors.text : colors.white};
            display: flex;
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f8};
            font-weight: ${fontWeight.light};
            height: 23px;
            padding: 0 16px 0 12px;
            text-transform: uppercase;
            width: fit-content;
        `,
);

export const ProfileImage = styled.img(
    ({ theme: { colors } }) =>
        css`
            border-radius: 100%;
            border: 1px solid ${colors.profileImageBorder};
            height: 35px;
            max-width: 35px;
            position: absolute;
            right: -24px;
            top: -6px;
            width: 35px;
            z-index: 1;
        `,
);

export const CommentWrapper = styled.div(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
        css`
            border-radius: 10px;
            border: 1px solid ${colors.error};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f10};
            font-weight: ${fontWeight.light};
            margin: 0 40px;
            max-width: 233px;
            padding: 6px 10px;
            position: relative;
            text-align: left;
        `,
);

export const CommentTitle = styled.p(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.error};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f8};
            font-weight: ${fontWeight.regular};
            left: 10px;
            position: absolute;
            top: -22px;
        `,
);

export const CommentLineWrapper = styled.div`
    left: -20px;
    position: absolute;
    top: 5px;
    z-index: 0;
`;
