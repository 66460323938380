import { PayloadAction } from '@reduxjs/toolkit';
import { setAudioUrl } from 'state/redux/slices/app';
import { TAppListenerAPI } from 'types/redux/redux';

export const appListeners = [
    {
        type: setAudioUrl.type,
        effect: async (
            { payload }: PayloadAction<boolean>,
            { dispatch }: TAppListenerAPI,
        ) => {
            console.log('example sideEffects');
        },
    },
];
