import { Route, Switch } from 'react-router-dom';
import { useScrollToTop } from 'utils/hooks/useScrollToTop';
import CustomRoute from './CustomRoute';
import routerConfig, { IMainRouteConfig } from './index';

const RootRouter = () => {
    useScrollToTop();

    const prepareLayoutPaths = (routes?: IMainRouteConfig[]) => {
        const layoutPaths: string[] = [];
        routes?.forEach((route) => {
            const subRoutes = route?.subRoutes?.routes?.map(({ path }) => path);
            layoutPaths.push(route.path, ...(subRoutes ?? []));
        });
        return layoutPaths;
    };

    return (
        <Switch>
            {routerConfig.map(
                (
                    {
                        allowFor,
                        isPrivate,
                        layoutComponent: Layout,
                        routes,
                        type,
                    },
                    index,
                ) => {
                    return (
                        <CustomRoute
                            allowFor={allowFor}
                            exact
                            isPrivate={isPrivate}
                            key={index}
                            path={prepareLayoutPaths(routes)}
                            routeType={type}
                        >
                            <Layout>
                                <Switch>
                                    {routes?.map(
                                        (
                                            { component, subRoutes, ...rest },
                                            routeIndex,
                                        ) => (
                                            <Route
                                                key={routeIndex}
                                                render={(props) => {
                                                    const Component =
                                                        component as React.ElementType;
                                                    return (
                                                        <Component
                                                            {...props}
                                                            {...subRoutes}
                                                        />
                                                    );
                                                }}
                                                {...rest}
                                            />
                                        ),
                                    )}
                                </Switch>
                            </Layout>
                        </CustomRoute>
                    );
                },
            )}
        </Switch>
    );
};

export default RootRouter;
