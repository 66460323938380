import { IconManager } from 'components/_universal/IconManager/IconManager';
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastProps } from 'react-toastify/dist/types';
import { ToastTypes } from 'types/CommonTypes';
import { CloseButton, StyledToastContainer } from './ToastNotification.styled';
import { NotificationBell } from 'shared/svgs/Icons';

export const notify = (contentText: React.ReactNode | string) => {
    return {
        [ToastTypes.ERROR]: () => toast.error(contentText),
        [ToastTypes.SUCCESS]: () => toast.success(contentText),
        [ToastTypes.WARNING]: () => toast.warning(contentText),
    };
};

export const ToastNotification = () => {
    const customCloseButton = ({ closeToast }: ToastProps) => {
        return (
            <CloseButton onClick={closeToast}>
                <IconManager cursorPointer name={'NotificationClose'} />
            </CloseButton>
        );
    };

    return (
        <StyledToastContainer
            //@ts-ignore
            closeButton={customCloseButton}
            autoClose={3000}
            hideProgressBar={true}
            icon={NotificationBell}
        />
    );
};
