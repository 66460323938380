import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    align-items: center;
    background: linear-gradient(278deg, #00938a -11.86%, #00c4b9 83.57%);
    display: flex;
    height: 80px;
    justify-content: space-between;
    left: 0;
    padding: 14px 82px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
`;

export const HeaderLink = styled(Link)`
    text-decoration: none;
`;
