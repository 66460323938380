import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'components/ToastNotification/ToastNotification';
import { TextareaComment } from 'components/_form-elements/TextareaComment/TextareaComment';
import { Button } from 'components/_universal/Button/Button';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Tooltip } from 'components/_universal/Tooltip/Tooltip';
import { AudioPlayer } from 'domains/MainPanel/components/AudioPlayer/AudioPlayer';
import {
    CommentButtonWrapper,
    CommentWrapper,
    CorrectText,
    IncorrectText,
    ModalSubTitle,
    ModalTitle,
    ModalWrapper,
    StatusWrapper,
    StyledModal,
    UndoButtonWrapper,
} from 'domains/MainPanel/components/AudioPlayer/AudioPlayerModal.styled';
import { HeaderCloseWrapper } from 'domains/MainPanel/components/TranscryptionVerificationModal/TranscryptionVerificationModal.styled';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
    VerificationStatus,
    useAuthMeQuery,
    useVerifyRecordingMutation,
} from 'src/graphql/generated';
import {
    selectAudioUrl,
    selectRecordingInfo,
    setAudioUrl,
    setRecordingInfo,
} from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { ToastTypes } from 'types/CommonTypes';
import { Status } from '../Status/Status';
import { validation } from './validation';

type FormFields = {
    comment: string;
};

export const AudioPlayerModal = () => {
    const selectedAudioUrl = useSelector(selectAudioUrl);
    const selectedRecordingInfo = useSelector(selectRecordingInfo);
    const [addingComment, setAddingComment] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [verifyRecordingMutation] = useVerifyRecordingMutation();

    const { data } = useAuthMeQuery();

    const {
        control,
        formState: { errors },
        watch,
    } = useForm<FormFields>({
        mode: 'all',
        resolver: yupResolver(validation),
    });

    const comment = watch('comment');

    const onClose = () => {
        dispatch(setAudioUrl(null));
        dispatch(setRecordingInfo(null));
    };

    const onSubmit = async (recordingStatus: VerificationStatus) => {
        try {
            if (comment && comment.length > 500) {
                return notify('Komentarz nie może przekraczać 500 znaków')[
                    ToastTypes.ERROR
                ]();
            }

            await verifyRecordingMutation({
                variables: {
                    recordingId: selectedRecordingInfo.id,
                    status: recordingStatus,
                    comment: comment ?? undefined,
                },
            });

            onClose();
        } catch (error) {
            return notify('Błąd podczas weryfikacji nagrania: ' + error)[
                ToastTypes.ERROR
            ]();
        }
    };

    return (
        <StyledModal>
            <ModalWrapper>
                <HeaderCloseWrapper onClick={() => onClose()}>
                    <IconManager name={'Close'} size={30} cursorPointer />
                </HeaderCloseWrapper>
                <ModalTitle>weryfikacja pliku audio</ModalTitle>
                <ModalSubTitle>{selectedRecordingInfo.name}</ModalSubTitle>
                {selectedRecordingInfo.status === 'PENDING' && (
                    <p>
                        Odsłuchaj nagranie, następnie oznacz jako <br />{' '}
                        <CorrectText>{`prawidłowe `}</CorrectText>
                        lub <IncorrectText> nieprawidłowe.</IncorrectText>
                    </p>
                )}
                <AudioPlayer url={selectedAudioUrl} />

                {selectedRecordingInfo.status === 'PENDING' && (
                    <CommentButtonWrapper>
                        <Button
                            type={'submit'}
                            onPress={() => {
                                onSubmit('ACCEPTED');
                            }}
                        >
                            prawidłowy
                        </Button>
                        <Button
                            type={'submit'}
                            buttonType={'secondary'}
                            onPress={() => setAddingComment(true)}
                        >
                            nieprawidłowy
                        </Button>
                    </CommentButtonWrapper>
                )}

                {addingComment && (
                    <CommentWrapper>
                        <TextareaComment
                            errors={errors?.comment}
                            name={'comment'}
                            control={control}
                            onClick={() => onSubmit('REJECTED')}
                        />
                    </CommentWrapper>
                )}

                {selectedRecordingInfo.status !== 'PENDING' && (
                    <StatusWrapper>
                        {isEditing ? (
                            <CommentWrapper>
                                <TextareaComment
                                    defaultValue={
                                        selectedRecordingInfo?.comment
                                    }
                                    errors={errors?.comment}
                                    name={'comment'}
                                    control={control}
                                    onClick={() => onSubmit('REJECTED')}
                                />
                            </CommentWrapper>
                        ) : (
                            <Status
                                comment={selectedRecordingInfo?.comment}
                                personAudio={
                                    selectedRecordingInfo?.commentUserInfo
                                }
                                status={selectedRecordingInfo?.status}
                            />
                        )}

                        {selectedRecordingInfo?.commentUserInfo?.id ===
                            data?.me?.id && (
                            <UndoButtonWrapper>
                                <Tooltip text={'Edytuj swój komentarz'}>
                                    <IconManager
                                        onClick={() => setIsEditing(!isEditing)}
                                        cursorPointer
                                        name={'EditTextAreaIcon'}
                                    />
                                </Tooltip>
                                <Tooltip text={'Reset statusu audio'}>
                                    <IconManager
                                        cursorPointer
                                        name={'UndoIcon'}
                                        onClick={() => onSubmit('PENDING')}
                                        size={24}
                                    />
                                </Tooltip>
                            </UndoButtonWrapper>
                        )}
                    </StatusWrapper>
                )}
            </ModalWrapper>
        </StyledModal>
    );
};
