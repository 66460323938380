import { palette, global } from './config';

const defaultTheme = {
    colors: {
        app_bg: palette.white,
        border: palette.grey,
        borderSecondary: palette.silver,
        buttonGrey: palette.iron,
        disable: palette.grey,
        waveBackground: palette.lightGrey,
        error: palette.red,
        grey: palette.grey,
        headerBg: palette.alto,
        hover: palette.blue,
        inputBorder: palette.iron,
        linkHover: palette.green31,
        paginationBorder: palette.emperor40,
        placeholder: palette.emperor,
        primary: palette.green,
        primaryButtonDisabled: palette.silver,
        profileBorder: palette.alto,
        profileImageBorder: palette.emperor,
        secondary: palette.red,
        tableHeaderBorder: palette.dustyGray,
        text: palette.emperor,
        textLine: palette.alto,
        white: palette.white,
        textAreaBg: palette.wildSand,
        formBlue: palette.pictonBlue,
        formNewFieldBlue: palette.dustyBlue,
    },
    ...global,
};

export default defaultTheme;
