import styled, { css } from 'styled-components';

export const EditableSectionWrapper = styled.div<{ isEditing: boolean }>(
    ({ theme: { colors, borderRadius }, isEditing }) =>
        css`
            border: ${isEditing
                ? '1px solid ' + colors.formBlue
                : '1px solid ' + colors.placeholder};
            border-radius: ${borderRadius.textarea}px;
            margin-bottom: 20px;
            padding: 16px 26px;
            position: relative;
        `,
);

export const EditIconWrapper = styled.div`
    background: #fff;
    height: 20px;
    position: absolute;
    right: 18px;
    top: -10px;
    width: 20px;
`;

export const SectionTitle = styled.p(
    ({ theme: { colors, fontSizes, fontFamily, fontWeight } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
    `,
);
