import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useSelector } from 'react-redux';
import { selectFilters, setFilters } from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import {
    FilterIndicator,
    FiltersDropdown,
    FiltersWrapper,
    HeaderTitle,
} from '../FiltersShared.styled';
import {
    SearchIconWrapper,
    SearchInput,
    SearchInsideWrapper,
} from './Search.styled';

export const Search = () => {
    const selectedFilters = useSelector(selectFilters);
    const searchVisible = selectedFilters?.searchIsOpen;
    const searchValue = selectedFilters?.searchValue;
    const searchIsActive = searchValue !== null;

    const handleInputChange = (e: any) => {
        dispatch(
            setFilters({
                searchValue: e.target.value === '' ? null : e.target.value,
            }),
        );
    };

    return (
        <div>
            <FiltersWrapper
                onClick={() => {
                    dispatch(setFilters({ searchIsOpen: !searchVisible }));
                }}
            >
                <HeaderTitle>Nazwa pliku</HeaderTitle>
                <IconManager
                    name="Arrow"
                    cursorPointer
                    rotate={searchVisible ? 0 : 180}
                />
                {searchIsActive && <FilterIndicator />}
            </FiltersWrapper>
            {searchVisible && (
                <FiltersDropdown filtersVisible={searchVisible}>
                    <SearchInsideWrapper>
                        <SearchInput
                            type="text"
                            value={searchValue}
                            onChange={handleInputChange}
                            placeholder="szukaj"
                        />
                        <SearchIconWrapper>
                            <IconManager name="SearchIcon" />
                        </SearchIconWrapper>
                    </SearchInsideWrapper>
                </FiltersDropdown>
            )}
        </div>
    );
};
