import { IconManager } from 'components/_universal/IconManager/IconManager';
import { SectionSubtitle, StyledInput } from 'domains/Form/Form.styled';
import {
    BlueBackground,
    FieldGroup,
    RemoveButtonWrapper,
    SymptomsWrapper,
} from '../Styles';
import { AllergiesFields } from 'domains/Form/types';

interface NewAllergyProps {
    index: number;
    allergy: AllergiesFields;
    onChange: (index: number, field: string, value: any) => void;
    onRemove: (index: number) => void;
    isEdit: boolean;
}

export const NewAllergy = ({
    index,
    allergy,
    onChange,
    onRemove,
    isEdit,
}: NewAllergyProps) => {
    const handleMedicationChange = (medIndex: number, value: string) => {
        const newMedications = allergy.medications.map((medication, idx) =>
            idx === medIndex ? value : medication,
        );
        if (medIndex === allergy.medications.length - 1 && value) {
            newMedications.push('');
        }
        onChange(index, 'medications', newMedications);
    };

    return (
        <SymptomsWrapper isEdit={isEdit}>
            <BlueBackground>
                <FieldGroup>
                    <SectionSubtitle>Nazwa alergii</SectionSubtitle>
                    <StyledInput
                        defaultValue={allergy.name}
                        disabled={!isEdit}
                        onChange={(e) =>
                            onChange(index, 'name', e.target.value)
                        }
                        placeholder="Dodaj alergię"
                    />
                    <StyledInput
                        defaultValue={allergy.description}
                        disabled={!isEdit}
                        onChange={(e) =>
                            onChange(index, 'description', e.target.value)
                        }
                        placeholder="Dodaj opis alergii"
                    />
                </FieldGroup>
                <FieldGroup>
                    <SectionSubtitle>Leki i dawkowanie</SectionSubtitle>
                    {allergy.medications.map((medication, medIndex) => (
                        <FieldGroup key={medIndex}>
                            <StyledInput
                                placeholder="Dodaj nazwę leku"
                                value={medication}
                                onChange={(e) =>
                                    handleMedicationChange(
                                        medIndex,
                                        e.target.value,
                                    )
                                }
                                disabled={!isEdit}
                            />
                        </FieldGroup>
                    ))}
                </FieldGroup>
            </BlueBackground>

            {isEdit && (
                <RemoveButtonWrapper onClick={() => onRemove(index)}>
                    <IconManager
                        onClick={() => onRemove(index)}
                        name={'TrashIcon'}
                    />
                </RemoveButtonWrapper>
            )}
        </SymptomsWrapper>
    );
};

export default NewAllergy;
