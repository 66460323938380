import styled, { css } from 'styled-components';

export const AddNewFieldWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            align-items: center;
            border-top: 1px solid ${colors.formBlue};
            display: flex;
            justify-content: space-between;
            margin: 16px -26px -16px -26px;
            padding: 16px 26px;
        `,
);

export const SectionTitle = styled.p(
    ({ theme: { colors, fontSizes, fontFamily, fontWeight } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
        margin: 0;
    `,
);
