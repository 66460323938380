import Select from 'react-select';
import { useUpdateTranscriptMutation } from 'src/graphql/generated';
import { customStyles } from './Select.styled';
import { notify } from 'components/ToastNotification/ToastNotification';
import { ToastTypes } from 'types/CommonTypes';

export const options = [
    { value: 'PATIENT', label: 'pacjent' },
    { value: 'DOCTOR', label: 'lekarz' },
    { value: 'NOT_SET', label: 'nieokreslony' },
];

export interface IProps {
    defaultValue: string;
    transcriptId: number;
}

export default ({ defaultValue, transcriptId }: IProps) => {
    const selectedOption = options.find(
        (option) => option.value === defaultValue,
    );

    const [updateTranscriptMutation] = useUpdateTranscriptMutation();

    const handleSelectChange = async (selectedValue: any) => {
        try {
            await updateTranscriptMutation({
                variables: {
                    input: {
                        transcriptId: transcriptId,
                        author: selectedValue?.value,
                    },
                },
                onCompleted: () => {
                    return notify('Pomyślnie zaktualizowano autora')[
                        ToastTypes.SUCCESS
                    ]();
                },
                onError: (error) => {
                    return notify('Coś poszło nie tak: ' + error.message)[
                        ToastTypes.ERROR
                    ]();
                },
                refetchQueries: 'active',
            });
        } catch (error) {
            console.error('Mutation error:', error);
        }
    };

    return (
        <Select
            defaultValue={selectedOption}
            name="people"
            styles={customStyles}
            options={options}
            onChange={handleSelectChange}
        />
    );
};
