import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';

dayjs.locale('pl');
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(isToday);

export const dateFormat = {
    standardDate: (date: Date | string): string =>
        dayjs(date).format('YYYY-MM-DD - HH:mm'),
    recorderDate: (date: Date | string): string =>
        dayjs(date).format('DD.MM.YYYY_HH:mm:ss'),
    standardTime: (date: Date | string): string => dayjs(date).format('HH:mm'),
    fullDate: (date: Date | string): string =>
        dayjs(date).format('DD MMMM YYYY'),
};
