import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Tooltip } from 'components/_universal/Tooltip/Tooltip';
import { SpeakerButtonWrapper } from 'domains/MainPanel/components/SpeakerButton/SpeakerButton.styled';
import { VerificationStatus } from 'src/graphql/generated';
import { setAudioUrl, setRecordingInfo } from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';

export interface IProps {
    audioUrl: string;
    comment: string;
    commentUserInfo: {
        id: number;
        image: string;
        name: string;
        position: string;
    } | null;
    recordingId: number;
    recordingName: string;
    recordingStatus: VerificationStatus;
}

export const SpeakerButton = ({
    audioUrl,
    comment,
    commentUserInfo,
    recordingId,
    recordingName,
    recordingStatus,
}: IProps) => {
    return (
        <SpeakerButtonWrapper>
            {audioUrl && (
                <Tooltip text={'Odsłuchaj nagranie'}>
                    <IconManager
                        name={'TableVolumeIcon'}
                        cursorPointer
                        onClick={() => {
                            dispatch(setAudioUrl(audioUrl));
                            dispatch(
                                setRecordingInfo({
                                    id: recordingId,
                                    name: recordingName,
                                    status: recordingStatus,
                                    comment: comment[0] ?? null,
                                    commentUserInfo: commentUserInfo ?? null,
                                }),
                            );
                        }}
                    />
                </Tooltip>
            )}
        </SpeakerButtonWrapper>
    );
};
