import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useSelector } from 'react-redux';
import { SortOrder } from 'src/graphql/generated';
import { selectFilters, setFilters } from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { Filters } from '../Filters/Filters';
import {
    FiltersDropdown,
    FiltersWrapper,
    HeaderTitle,
} from '../FiltersShared.styled';

export const DateFilters = () => {
    const selectedFilters = useSelector(selectFilters);
    const dateFiltersVisible = selectedFilters?.dateIsOpen;
    const selectedFilter = selectedFilters?.dateValue;

    const filterOptions = [
        { label: 'najnowsze', value: 'desc' },
        { label: 'najstarsze', value: 'asc' },
    ];

    const handleFilterChange = (filterValue: SortOrder) => {
        dispatch(setFilters({ dateValue: filterValue }));
    };

    return (
        <div>
            <FiltersWrapper
                onClick={() => {
                    dispatch(setFilters({ dateIsOpen: !dateFiltersVisible }));
                }}
            >
                <HeaderTitle>Data nagrania</HeaderTitle>
                <IconManager
                    name="Arrow"
                    cursorPointer
                    rotate={dateFiltersVisible ? 0 : 180}
                />
            </FiltersWrapper>
            {dateFiltersVisible && (
                <FiltersDropdown filtersVisible={dateFiltersVisible}>
                    <Filters
                        id={'date'}
                        onFilterChange={handleFilterChange}
                        options={filterOptions}
                        selectedFilter={selectedFilter}
                    />
                </FiltersDropdown>
            )}
        </div>
    );
};
