import { notify } from 'components/ToastNotification/ToastNotification';
import { customStyles } from 'components/_form-elements/Select/Select.styled';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Loader } from 'components/_universal/Loader/Loader';
import { Tooltip } from 'components/_universal/Tooltip/Tooltip';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { useTable } from 'react-table';
import LocalStorageManager from 'services/LocalStorageManager/LocalStorageManager';
import paths from 'shared/paths';
import {
    useCreateRecordingMutation,
    useListRecordingsLazyQuery,
} from 'src/graphql/generated';
import { selectFilters, setFilters } from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { EventMessage, ToastTypes } from 'types/CommonTypes';
import { dateFormat } from 'utils/dateFormat';
import useDebounce from 'utils/hooks/useDebounce';
import useSocket from 'utils/hooks/useSocket';
import {
    AddAudioButtonWrapper,
    FiltersShadow,
    PaginationWrapper,
    StyledButton,
    TableWrapper,
} from './Table.styled';
import { getNotificationMessage } from './useNotifyUpdate';
import { useOnClickOutside } from 'utils/hooks';
import { Button } from 'components/_universal/Button/Button';

export interface TableData {
    name: string;
    audioFormat: string;
    audio: any;
    audioStatus: string;
    transcriptionFormat: string;
    transcription: any;
    transcriptionStatus: string;
    form: any;
}

export interface PaginationData {
    pageCount: number;
    currentPage: number;
}

export interface TableProps {
    columns: any[];
}

export interface OptionType {
    label: string;
    value: string;
}

export const selectOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
];

export const Table = ({ columns }: TableProps) => {
    const history = useHistory();
    const tableRef = useRef<HTMLDivElement>(null);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [selectedPageSize, setSelectedPageSize] = useState(
        selectOptions.find((option) => option.value === PAGE_SIZE),
    );
    const ua = window.navigator.userAgent;
    const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

    const selectedFilters = useSelector(selectFilters);
    const fileType = selectedFilters?.audioRecordingValue;
    const verificationStatus = selectedFilters?.audioStatusValue;
    const search = selectedFilters?.searchValue;
    const sortOrder = selectedFilters?.dateValue;
    const transcriptVerificationStatus =
        selectedFilters?.transcriptionStatusValue;

    const isFilterActive =
        fileType !== 'all' ||
        verificationStatus !== 'all' ||
        transcriptVerificationStatus !== 'all' ||
        sortOrder !== 'desc' ||
        search !== null;

    const isFiltersOpen =
        selectedFilters.audioRecordingIsOpen ||
        selectedFilters.audioStatusIsOpen ||
        selectedFilters.dateIsOpen ||
        selectedFilters.searchIsOpen ||
        selectedFilters.transcriptionStatusIsOpen;

    const inputRef = useRef<HTMLInputElement>(null);
    const [createRecording, { loading: loadingCreateRecording }] =
        useCreateRecordingMutation();

    const [listRecordings, { data }] = useListRecordingsLazyQuery({
        onError: (error) => {
            if (error.message === 'Session not found') {
                history.push(paths.login);
                LocalStorageManager.clear();
            }
        },
        fetchPolicy: 'cache-and-network',
    });

    const fetchListRecordings = (isSelect: boolean, value?: number) => {
        listRecordings({
            variables: {
                skip: isSelect ? 0 : currentPage * pageSize,
                take: isSelect ? (value ? value : 0) : pageSize,
                sortBy: 'createdAt',
                sortOrder: sortOrder,
                filters: {
                    fileType: fileType === 'all' ? null : fileType,
                    search: search,
                    transcriptVerificationStatus:
                        transcriptVerificationStatus === 'all'
                            ? null
                            : transcriptVerificationStatus,
                    verificationStatus:
                        verificationStatus === 'all'
                            ? null
                            : verificationStatus,
                },
            },
        });
    };

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            try {
                const file = files[0];
                await createRecording({
                    variables: {
                        file,
                    },
                    onCompleted: () => {
                        return notify('Dodano nagranie')[ToastTypes.SUCCESS]();
                    },
                    onError: (error) => {
                        return notify('Błąd dodawania nagrania: ' + error)[
                            ToastTypes.ERROR
                        ]();
                    },
                    refetchQueries: 'active',
                });
            } catch (error) {
                console.error('Błąd przesyłania pliku:', error);
            }
        }
    };

    const handleButtonClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleResetFilters = () => {
        dispatch(
            setFilters({
                audioRecordingIsOpen: false,
                audioRecordingValue: 'all',
                audioStatusIsOpen: false,
                audioStatusValue: 'all',
                dateIsOpen: false,
                dateValue: 'desc',
                searchIsOpen: false,
                searchValue: null,
                transcriptionStatusIsOpen: false,
                transcriptionStatusValue: 'all',
            }),
        );
    };

    const handleWebSocketEvent = (eventMessage: EventMessage) => {
        const message = getNotificationMessage(eventMessage);
        notify(message)[ToastTypes.SUCCESS]();

        fetchListRecordings(false);
    };

    const handleSelectChange = (selectedOption: any) => {
        if (selectedOption) {
            const { value } = selectedOption as {
                value: number;
                label: string;
            };
            setSelectedPageSize(selectedOption);
            setPageSize(value);
            setCurrentPage(0);
            fetchListRecordings(true, value);
        }
    };

    const listRecordingsData = data?.listRecordings?.data;

    const tableData = useMemo(() => {
        return (
            listRecordingsData?.map((item) => ({
                createdAt: dateFormat.standardDate(item?.createdAt),
                audioComment: item?.verifications?.map(
                    (comments) => comments?.comment,
                ),
                audioUrl: item?.audio?.url ?? '',
                name: item?.audio?.name ?? '',
                hasReport: item?.hasReport,
                profileAudio: {
                    image: item?.verifications?.[0]?.user?.avatar,
                    name: item?.verifications?.[0]?.user?.name,
                    position: item?.verifications?.[0]?.user?.role,
                    id: item?.verifications?.[0]?.user?.id,
                },
                recordingId: item?.id,
                recordingStatus: item?.status,
                isReportQueued: item?.isReportQueued,
                transcriptLoadStatus: item?.transcriptStatus,
                transcriptVerificationStatus:
                    item?.transcriptVerificationStatus,
                wholeTranscriptVerifiedBy: item?.wholeTranscriptVerifiedBy,
                isWholeTranscriptVerified: item?.isWholeTranscriptVerified,
            })) ?? []
        );
    }, [data]);

    const tableInstance = useTable({ data: tableData, columns });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    const debouncedSearch = useDebounce(search, 1000);

    const closeFilters = () => {
        dispatch(
            setFilters({
                searchIsOpen: false,
                audioRecordingIsOpen: false,
                audioStatusIsOpen: false,
                dateIsOpen: false,
                transcriptionStatusIsOpen: false,
            }),
        );
    };

    useOnClickOutside(tableRef, closeFilters);

    useSocket(handleWebSocketEvent);

    useEffect(() => {
        fetchListRecordings(false);
    }, [
        currentPage,
        debouncedSearch,
        fileType,
        pageSize,
        sortOrder,
        transcriptVerificationStatus,
        verificationStatus,
    ]);

    useEffect(() => {
        fetchListRecordings(false);

        return () => {
            dispatch(
                setFilters({
                    audioRecordingIsOpen: false,
                    audioRecordingValue: 'all',
                    audioStatusIsOpen: false,
                    audioStatusValue: 'all',
                    dateIsOpen: false,
                    dateValue: 'desc',
                    searchIsOpen: false,
                    searchValue: null,
                    transcriptionStatusIsOpen: false,
                    transcriptionStatusValue: 'all',
                }),
            );
        };
    }, []);

    return (
        <TableWrapper ref={tableRef} isSafari={isSafari}>
            <AddAudioButtonWrapper>
                <Tooltip text={'Dodaj swoje nagranie, które trafi do bazy AI'}>
                    <input
                        type="file"
                        ref={inputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept=".mp3, .wav, .m4a"
                    />
                    <StyledButton
                        icon={{ type: 'AddIcon' }}
                        onPress={handleButtonClick}
                    >
                        Dodaj nagranie
                    </StyledButton>
                    {loadingCreateRecording && <Loader size={35} />}
                </Tooltip>
                {isFilterActive && (
                    <Button
                        buttonType="grey"
                        icon={{ type: 'NotificationClose' }}
                        onPress={handleResetFilters}
                    >
                        Resetuj filtry
                    </Button>
                )}
            </AddAudioButtonWrapper>

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {isFiltersOpen && (
                        <FiltersShadow
                            isSafari={isSafari}
                            onClick={() => closeFilters()}
                        />
                    )}
                </tbody>
            </table>
            <PaginationWrapper>
                {isFiltersOpen && (
                    <FiltersShadow onClick={() => closeFilters()} pagination />
                )}
                <Select
                    className={'select'}
                    menuPosition={'fixed'}
                    name="pageSize"
                    onChange={handleSelectChange}
                    options={selectOptions}
                    styles={customStyles}
                    value={selectedPageSize}
                />
                <ReactPaginate
                    previousLabel={
                        <IconManager
                            fill={['primary']}
                            name={'Arrow'}
                            rotate={-90}
                            size={24}
                        />
                    }
                    nextLabel={
                        <IconManager
                            fill={['primary']}
                            name={'Arrow'}
                            rotate={90}
                            size={24}
                        />
                    }
                    pageCount={Math.ceil(
                        (data?.listRecordings?.total ?? 0) / pageSize,
                    )}
                    onPageChange={(selectedItem) =>
                        setCurrentPage(selectedItem?.selected)
                    }
                    forcePage={currentPage}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'pagination__link--active'}
                />
            </PaginationWrapper>
        </TableWrapper>
    );
};
