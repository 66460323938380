import { appRoutes } from './specificRoutes/appRoutes';
import { authRoutes } from './specificRoutes/authRoutes';
import { commonRoutes } from './specificRoutes/commonRoutes';
import { RouteProps } from 'react-router-dom';
import { USER_TYPE } from 'types/CommonTypes';

export interface IRouteConfig extends RouteProps {
    path: string;
}
export interface IMainRouteConfig extends IRouteConfig {
    subRoutes?: IAppSubRoutes;
}
export interface IAppRoutes {
    allowFor?: USER_TYPE;
    isPrivate?: boolean;
    layoutComponent: React.ElementType;
    routes?: IMainRouteConfig[];
    type?: 'invalid' | 'signUpProcess';
}

export interface IAppSubRoutes {
    layoutComponent: React.ElementType;
    routes?: IRouteConfig[];
}

export type RouterConfig = IAppRoutes[];

const routerConfig: RouterConfig = [
    ...authRoutes,
    ...appRoutes,
    ...commonRoutes,
];

export default routerConfig;
