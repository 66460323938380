import { useHistory } from 'react-router-dom';
import paths from 'shared/paths';
import {
    ButtonsWrapper,
    Description,
    Error404PageWrapper,
    StyledButton,
    StyledIconManager,
    StyledTitle,
} from './Error404Page.styled';

interface IProps {}

const Error404Page: React.FC<IProps> = () => {
    const history = useHistory();

    return (
        <Error404PageWrapper>
            <StyledIconManager name={'ErrorIcon'} size={618} sizeY={421} />
            <StyledTitle>Oh no, something went wrong!</StyledTitle>
            <Description>
                We cannot find the page you are looking for. But do not worry,
                with the help of this button below you will easily find your way
                back.
            </Description>

            <ButtonsWrapper>
                <StyledButton onPress={() => history.push(paths.home)}>
                    Go back to dashboard
                </StyledButton>
                <StyledButton onPress={() => history.push(paths.login)}>
                    Log in
                </StyledButton>
            </ButtonsWrapper>
        </Error404PageWrapper>
    );
};

export default Error404Page;
