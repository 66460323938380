import styled, { css } from 'styled-components';

export const TooltipWrapper = styled.div(
    () => css`
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
    `,
);

export const TooltipTrigger = styled.div`
    display: inline-block;
`;

export const TooltipBox = styled.div(
    () => css`
        align-items: center;
        background: rgba(98, 98, 99, 0.7);
        border-radius: 3px;
        bottom: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        left: 100%;
        min-width: 150px;
        padding: 4px 6px;
        position: absolute;
        transform: translate(-50%, 0);
        white-space: normal;
        z-index: 2;
    `,
);

export const TooltipTitle = styled.p(
    ({ theme: { fontSizes, fontFamily } }) =>
        css`
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f11};
            line-height: 16px;
            text-transform: uppercase;
            margin: 0;
        `,
);

export const TooltipText = styled.p(
    ({ theme: { fontSizes, fontFamily } }) =>
        css`
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f10};
            line-height: 16px;
            margin: 0;
        `,
);

export const TooltipPosition = styled.p(
    ({ theme: { fontSizes, fontFamily } }) =>
        css`
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f6};
            line-height: 9px;
            text-transform: uppercase;
            margin: 0;
        `,
);
