import styled, { css } from 'styled-components';
import { SubTitle, Title } from 'components/_universal/Typography.styled';
import { TextInput } from 'components/_form-elements/TextInput/TextInput';
import { Button } from 'components/_universal/Button/Button';

export const ResetPasswordPageWrapper = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        background-color: ${colors.app_bg};
        color: ${colors.text};
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
    `,
);

export const ResetPasswordPageBox = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.app_bg};
        border-radius: 35px;
        box-shadow: 0px 0px 15px rgba(84, 84, 84, 0.3);
        padding: 77px 148px 46px;
    `,
);

export const FormTitle = styled(SubTitle)`
    margin-bottom: 90px;
`;

export const FromSubTitle = styled(Title)`
    font-weight: 400;
    margin-bottom: 64px;
`;

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    min-width: 354px;
    width: 100%;
`;

export const StyledInput = styled(TextInput)``;

export const StyledButton = styled(Button)`
    margin: 24px 0 18px 0;
`;
