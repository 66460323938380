import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { FieldError, FieldErrors } from 'react-hook-form';
import { useUpdateTranscriptMutation } from 'src/graphql/generated';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import {
    TextareaEditIconWrapper,
    TextareaInput,
    TextareaSendIconWrapper,
    TextareaTimeWrapper,
    TextareaWrapper,
} from './Textarea.styled';
import { ToastTypes } from 'types/CommonTypes';
import { notify } from 'components/ToastNotification/ToastNotification';

export interface TextareaProps {
    defaultValue?: string;
    disabled?: boolean;
    end: number;
    errors?: FieldError | FieldErrors;
    name: string;
    onClick?: () => void;
    placeholder?: string;
    start: number;
    transcriptId: number;
}

export const Textarea = ({
    defaultValue,
    disabled,
    end,
    errors,
    name,
    onClick,
    placeholder,
    start,
    transcriptId,
}: TextareaProps) => {
    const [text, setText] = useState(defaultValue);
    const [textAreaEditable, setTextAreaEditable] = useState(false);
    // const [editStart, setEditStart] = useState(start);
    // const [editEnd, setEditEnd] = useState(end);

    const [updateTranscriptMutation] = useUpdateTranscriptMutation();

    const handleMutation = async () => {
        try {
            await updateTranscriptMutation({
                variables: {
                    input: {
                        transcriptId: transcriptId,
                        text: text,
                        // start: editStart,
                        // end: editEnd,
                    },
                },
                onCompleted: () => {
                    setTextAreaEditable(false);
                    return notify('Pomyślnie zaktualizowano transkrypcje')[
                        ToastTypes.SUCCESS
                    ]();
                },
                onError: (error) => {
                    setTextAreaEditable(false);
                    return notify('Coś poszło nie tak: ' + error.message)[
                        ToastTypes.ERROR
                    ]();
                },
            });
        } catch (error) {
            console.error('Mutation error:', error);
        }
    };

    useEffect(() => {
        setText(defaultValue);
        // setEditStart(start);
        // setEditEnd(end);
    }, [defaultValue, start, end]);

    return (
        <TextareaWrapper isError={!!errors}>
            <TextareaTimeWrapper>
                {
                    // textAreaEditable ? (
                    //     <>
                    //         <input
                    //             type="number"
                    //             value={start}
                    //             // onChange={(e) =>
                    //             //     setEditStart(Number(e.target.value))
                    //             // }
                    //             disabled={!textAreaEditable}
                    //         />
                    //         {' - '}
                    //         <input
                    //             type="number"
                    //             value={end}
                    //             // onChange={(e) => setEditEnd(Number(e.target.value))}
                    //             disabled={!textAreaEditable}
                    //         />
                    //     </>
                    // ) :
                    `${start} - ${end}`
                }
            </TextareaTimeWrapper>
            <TextareaEditIconWrapper
                onClick={() => setTextAreaEditable(!textAreaEditable)}
            >
                <IconManager
                    cursorPointer
                    fill={textAreaEditable ? ['text'] : ['primary']}
                    name={'EditTextAreaIcon'}
                    size={22}
                />
            </TextareaEditIconWrapper>
            {textAreaEditable && (
                <TextareaSendIconWrapper onClick={handleMutation}>
                    <IconManager
                        cursorPointer
                        name={'TextAreaGreenSendIcon'}
                        size={22}
                    />
                </TextareaSendIconWrapper>
            )}
            <TextareaInput
                disabled={disabled ? disabled : !textAreaEditable}
                id={name}
                isDisabled={disabled ? disabled : !textAreaEditable}
                isError={!!errors}
                name={name}
                onChange={(e) => setText(e.target.value)}
                onClick={onClick}
                onFocus={onClick}
                placeholder={placeholder}
                value={text}
            />
            {errors && (
                <Fade direction="up" triggerOnce>
                    <ErrorMessage error={errors} />
                </Fade>
            )}
        </TextareaWrapper>
    );
};
