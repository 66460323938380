import styled, { css } from 'styled-components';
import CheckIcon from 'assets/svgs/CheckIcon.svg';

export const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FilterOption = styled.label(
    ({ theme: { fontFamily } }) => css`
        display: block;
        position: relative;
        color: #545454;
        font-family: ${fontFamily.secondary};
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        user-select: none;
    `,
);

export const FilterInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
        background-color: $fff;
    }

    &:checked ~ .checkmark:after {
        display: block;
    }
`;

export const Checkmark = styled.span`
    position: absolute;
    top: 8px;
    left: 8px;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid #545454;

    &:after {
        content: '';
        position: absolute;
        display: none;

        top: -2px;
        left: -2px;
        width: 15px;
        height: 15px;
        border-radius: 4px;
        background-color: #00c4b9;
        background-image: url(${CheckIcon});
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
    }
`;
