import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Tooltip } from 'components/_universal/Tooltip/Tooltip';
import { VerificationStatus } from 'src/graphql/generated';
import {
    CommentLineWrapper,
    CommentTitle,
    CommentWrapper,
    ProfileImage,
    StatusButton,
    StatusIconWrapper,
    StatusWrapper,
    CommentIconWrapper,
} from './Status.styled';

export interface IProps {
    comment?: string;
    commentIcon?: string;
    personAudio?: { name: string; image: string; position: string };
    status: VerificationStatus;
}

export const Status = ({
    comment,
    commentIcon,
    personAudio,
    status,
}: IProps) => {
    return (
        <StatusWrapper>
            {status === 'ACCEPTED' && (
                <>
                    <StatusIconWrapper>
                        <IconManager
                            size={13}
                            name={'TableApprovedStatusIcon'}
                        />
                    </StatusIconWrapper>
                    <StatusButton status={true}>zatwierdzono</StatusButton>
                </>
            )}
            {status === 'REJECTED' && (
                <>
                    <StatusIconWrapper>
                        <IconManager size={13} name={'TableStatusRedIcon'} />
                    </StatusIconWrapper>
                    <StatusButton status={false}>odrzucono</StatusButton>
                </>
            )}
            {status === 'PENDING' && 'do weryfikacji'}
            {personAudio?.image && (
                <Tooltip
                    title={personAudio?.name}
                    position={personAudio?.position}
                >
                    <ProfileImage src={personAudio?.image} />
                </Tooltip>
            )}
            {comment && (
                <CommentWrapper>
                    {comment}
                    <CommentLineWrapper>
                        <IconManager size={24} name={'KommentLine'} />
                    </CommentLineWrapper>
                    <CommentTitle>KOMENTARZ</CommentTitle>
                </CommentWrapper>
            )}
            {commentIcon && commentIcon?.[0]?.length > 0 && (
                <CommentIconWrapper>
                    <Tooltip title={'Komentarz'} text={commentIcon[0]}>
                        <IconManager name={'TableCommentIcon'} />
                    </Tooltip>
                </CommentIconWrapper>
            )}
        </StatusWrapper>
    );
};
