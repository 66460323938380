import { createContext, useContext } from 'react';

export interface ThemeContextType {
    modalIsOpen: boolean;
    setModalIsOpen: (modalIsOpen: boolean) => void;
}

export const ThemeContext = createContext<ThemeContextType>({
    modalIsOpen: false,
    setModalIsOpen: () => undefined,
});

export const useTheme = () => useContext(ThemeContext);
