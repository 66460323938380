export const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        background: 'white',
        color: '#545454',
        cursor: 'pointer',
        padding: '10px 16px',

        '&:last-child': {
            paddingBottom: '14px',
        },
    }),
    container: (provided: any) => ({
        ...provided,
        background: 'transparent',
        minWidth: '155px',
        height: 'fit-content',
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        border: '1px solid #BBB',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '25px',
        '&:hover': {
            cursor: 'pointer',
        },
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    menu: (provided: any) => ({
        ...provided,
        //TODO: remove if not necessary
        color: '#545454',
        zIndex: '9',
        fontWeight: '400',
        margin: '0px',
        borderRadius: '25px',
        boxShadow: '0px 5px 20px rgba(70, 107, 162, 0.15)',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: '#545454',
        fontSize: '16px',
        fontWeight: '400',
    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        paddingLeft: '20px',
        color: '#545454',
        fontSize: '16px',
        transform: state.isFocused
            ? 'translate(0, 0%) scale(1)'
            : 'transform: translate(0, -50%) scale(1)',
        fontWeight: '700',
        letterSpacing: '0.01em',
    }),
    menuList: (provided: any) => ({
        ...provided,
        margin: '0px',
        padding: '0px',
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};
