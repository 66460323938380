import styled, { css } from 'styled-components';

export const Input = styled.input<{
    isDisabled?: boolean;
    isError?: boolean;
    isIcon?: boolean;
}>(
    ({
        isDisabled,
        isError,
        isIcon,
        theme: { colors, fontFamily, fontSizes, fontWeight, borderRadius },
    }) => css`
        background: ${colors.app_bg};
        border-radius: ${borderRadius.default};
        border: 1px solid ${isError ? colors.error : colors.inputBorder};
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.regular};
        opacity: ${isDisabled ? 0.5 : 1};
        outline: none;
        padding: ${isIcon ? '15px 42px 15px 52px' : '15px 18px'};
        width: 100%;

        &::-webkit-input-placeholder,
        &::-ms-input-placeholder {
            color: ${colors.placeholder};
        }

        &:active,
        &:focus {
            border-color: ${isError ? colors.error : colors.placeholder};
        }
    `,
);

export const InputLabel = styled.span(
    ({ theme: { fontFamily, fontSizes, colors } }) =>
        css`
            color: ${colors.text}99;
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f12};
            line-height: 30px;
            margin-left: 18px;
        `,
);

export const InputWrapper = styled.div<{
    marginBottom?: number;
    isError?: boolean;
}>(
    ({ marginBottom, isError }) => css`
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin-bottom: ${isError
            ? '6px'
            : marginBottom
            ? marginBottom + 'px'
            : '0'};
    `,
);

export const IconWrapper = styled.div`
    left: 15px;
    position: absolute;
    top: 13px;
`;

export const EyeIconWrapper = styled.div`
    position: absolute;
    right: 15px;
    top: 13px;
`;
