import React from 'react';
import { AuthLayoutWrapper } from './AuthLayout.styled';

interface IProps {
    children: any;
}

export const AuthLayout: React.FC<IProps> = ({ children }) => {
    return <AuthLayoutWrapper>{children}</AuthLayoutWrapper>;
};
