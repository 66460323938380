import { useState, useEffect } from 'react';

type DataType = Record<string, any> | any[] | null | undefined;

const useRemoveTypename = (data: DataType): DataType => {
    const [cleanedData, setCleanedData] = useState<DataType>(null);

    useEffect(() => {
        const removeTypename = (obj: DataType): DataType => {
            if (Array.isArray(obj)) {
                return obj.map(removeTypename);
            } else if (obj !== null && typeof obj === 'object') {
                const newObj: Record<string, any> = {};
                for (const key in obj) {
                    if (key !== '__typename') {
                        newObj[key] = removeTypename(obj[key]);
                    }
                }
                return newObj;
            }
            return obj;
        };

        setCleanedData(removeTypename(data));
    }, [data]);

    return cleanedData;
};

export default useRemoveTypename;
