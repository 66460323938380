import { IconManager } from 'components/_universal/IconManager/IconManager';
import {
    EditableSectionWrapper,
    EditIconWrapper,
    SectionTitle,
} from './EditableSection.styled';

interface IProps {
    title: string;
    children: React.ReactNode;
    isEditing: boolean;
    toggleEditing: () => void;
}

export const EditableSection = ({
    title,
    children,
    isEditing,
    toggleEditing,
}: IProps) => (
    <>
        <SectionTitle>{title}</SectionTitle>
        <EditableSectionWrapper isEditing={isEditing}>
            <EditIconWrapper onClick={toggleEditing}>
                <IconManager
                    name={isEditing ? 'EditBlueFormIcon' : 'EditFormIcon'}
                    cursorPointer
                />
            </EditIconWrapper>
            {children}
        </EditableSectionWrapper>
    </>
);
