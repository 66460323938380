import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Tooltip } from 'components/_universal/Tooltip/Tooltip';
import { TranscriptVerificationStatus } from 'src/graphql/generated';
import {
    ProfileImage,
    StatusButton,
    StatusIconWrapper,
    StatusWrapper,
} from './TranscriptionStatus.styled';

export interface IProps {
    isWholeTranscriptVerified: boolean;
    transcriptVerificationStatus: TranscriptVerificationStatus;
    wholeTranscriptVerifiedBy: {
        avatar: string;
        email: string;
        id: number;
        name: string;
        role: string;
    };
}
export const TranscriptionStatus = ({
    isWholeTranscriptVerified,
    transcriptVerificationStatus,
    wholeTranscriptVerifiedBy,
}: IProps) => {
    const isAccepted = transcriptVerificationStatus === 'VERIFIED';

    if (!isWholeTranscriptVerified) {
        return <StatusWrapper>-</StatusWrapper>;
    }

    return (
        <StatusWrapper>
            <StatusIconWrapper>
                <IconManager
                    size={13}
                    name={
                        isAccepted
                            ? 'TableApprovedStatusIcon'
                            : 'TableStatusRedIcon'
                    }
                />
            </StatusIconWrapper>
            <StatusButton status={isAccepted}>
                {isAccepted ? 'zatwierdzono' : 'odrzucono'}
            </StatusButton>
            <Tooltip
                title={wholeTranscriptVerifiedBy?.name}
                position={wholeTranscriptVerifiedBy?.role}
            >
                <ProfileImage src={wholeTranscriptVerifiedBy?.avatar} />
            </Tooltip>
        </StatusWrapper>
    );
};
