import { EnvType } from 'types/CommonTypes';

export const __DEV__ = process.env.NODE_ENV !== EnvType.production;
export const API_URL = process.env.REACT_APP_GRAPHQL;

export const breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
};

export const ERROR_REDUX_REJECTED = 'Rejected'; // error message from isRejectedWithValue
export const ERROR_REDUX_ABORTED = 'Aborted'; // error message from aborting dispatch
export const ERROR_AXIOS_CANCELED = 'canceled'; // error message from axios when aborting request
