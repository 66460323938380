import styled, { css } from 'styled-components';

export const TextareaInput = styled.textarea<{
    isDisabled?: boolean;
    isError?: boolean;
}>(
    ({
        isDisabled,
        isError,
        theme: { colors, fontFamily, fontSizes, fontWeight, borderRadius },
    }) => css`
        background: ${colors.textAreaBg};
        border-radius: ${borderRadius.textarea}px;
        border: 2px solid ${isError ? colors.error : colors.inputBorder};
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.light};
        opacity: ${isDisabled ? 0.5 : 1};
        outline: none;
        padding: 16px 60px 16px 16px;
        width: 100%;
        resize: none;

        &::-webkit-input-placeholder,
        &::-ms-input-placeholder {
            color: ${colors.placeholder};
        }

        &:active,
        &:focus {
            border-color: ${isError ? colors.error : colors.primary};
        }
    `,
);

export const TextareaWrapper = styled.div<{
    isError?: boolean;
}>(
    ({ isError }) => css`
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin-bottom: ${isError ? '6px' : '0'};
    `,
);

export const TextareaEditIconWrapper = styled.div`
    position: absolute;
    right: 12px;
    top: 8px;
    z-index: 1;
`;

export const TextareaSendIconWrapper = styled.div`
    position: absolute;
    right: 40px;
    top: 9px;
    z-index: 1;
`;

export const TextareaTimeWrapper = styled.div(
    ({ theme: { fontSizes, colors } }) =>
        css`
            background: ${colors.textAreaBg};
            font-size: ${fontSizes.f12};
            left: 25px;
            line-height: 17px;
            position: absolute;
            top: -7px;
            z-index: 1;
            padding: 0 12px;

            input {
                border: 1px solid ${colors.inputBorder};
            }
        `,
);
