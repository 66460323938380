import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useSelector } from 'react-redux';
import { TranscriptStatusFilters } from 'src/graphql/generated';
import { selectFilters, setFilters } from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { Filters } from '../Filters/Filters';
import {
    FilterIndicator,
    FiltersDropdown,
    FiltersWrapper,
    HeaderTitle,
} from '../FiltersShared.styled';

export const TranscriptionStatusFilters = () => {
    const selectedFilters = useSelector(selectFilters);
    const transcriptionStatusIsOpen =
        selectedFilters?.transcriptionStatusIsOpen;
    const transcriptionStatusValue = selectedFilters?.transcriptionStatusValue;
    const filterIsActive = transcriptionStatusValue !== 'all';

    const filterOptions = [
        { label: 'wszystkie', value: 'all' },
        { label: 'zatwierdzone', value: 'VERIFIED' },
        { label: 'odrzucone', value: 'NOT_VERIFIED' },
        { label: 'niewygenerowane', value: 'NOT_GENERATED' },
        { label: 'oczekujące', value: 'IN_PROGRESS' },
    ];

    const handleFilterChange = (filterValue: TranscriptStatusFilters) => {
        dispatch(setFilters({ transcriptionStatusValue: filterValue }));
    };

    return (
        <div>
            <FiltersWrapper
                onClick={() => {
                    dispatch(
                        setFilters({
                            transcriptionStatusIsOpen:
                                !transcriptionStatusIsOpen,
                        }),
                    );
                }}
            >
                <HeaderTitle>Status transkrypcji</HeaderTitle>
                <IconManager
                    name="Arrow"
                    cursorPointer
                    rotate={transcriptionStatusIsOpen ? 0 : 180}
                />
                {filterIsActive && <FilterIndicator />}
            </FiltersWrapper>
            {transcriptionStatusIsOpen && (
                <FiltersDropdown filtersVisible={transcriptionStatusIsOpen}>
                    <Filters
                        id={'TranscriptionStatus'}
                        onFilterChange={handleFilterChange}
                        options={filterOptions}
                        selectedFilter={transcriptionStatusValue}
                    />
                </FiltersDropdown>
            )}
        </div>
    );
};
