import styled, { css } from 'styled-components';

export const SymptomsWrapper = styled.div<{ isEdit?: boolean }>(
    ({ theme: { colors }, isEdit }) =>
        css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            position: relative;
            margin-top: 20px;

            &::before {
                background: ${isEdit ? colors.formBlue : colors.placeholder};
                content: '';
                height: 1px;
                width: calc(100% + 52px);
                margin: 0 -26px;
                margin-bottom: 10px;
            }
        `,
);

export const RemoveButtonWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 20px;
`;

export const SymptomsInnerWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const BlueBackground = styled.div(
    ({ theme: { colors } }) =>
        css`
            background: ${colors.formNewFieldBlue};
            border-radius: 10px;
            padding: 12px;
            margin-right: 34px;
        `,
);

export const FieldGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
