import styled, { css } from 'styled-components';

export const FormWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 6px;
    justify-content: center;
    margin-left: 14px;
    position: relative;
    width: fit-content;
`;

export const FormIconWrapper = styled.div`
    filter: drop-shadow(0px 3px 4px rgba(151, 151, 151, 0.33));
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%, -25%);
`;
