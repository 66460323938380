import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const MenuWrapper = styled.div`
    align-self: flex-start;
    display: flex;
    gap: 58px;
    margin: 80px 0 0 14px;
    padding: 14px 82px;
`;

export const MenuLink = styled(Link)<{ active?: boolean }>(
    ({ theme: { fontFamily, colors, fontSizes, fontWeight }, active }) => css`
        color: ${active ? colors.primary : colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        font-weight: ${active ? fontWeight.medium : fontWeight.regular};
        line-height: 30px;
        text-decoration: none;
        text-transform: uppercase;
    `,
);
