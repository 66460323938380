import { useEffect } from 'react';
import { Socket, io } from 'socket.io-client';
import { EventMessage } from 'types/CommonTypes';

const EVENT_TOPIC = 'voice-events';
const SOCKET_URL = 'wss://backend.voice.codahead.com/';

const useSocket = (onEvent: (eventMessage: EventMessage) => void): void => {
    useEffect(() => {
        const socket: Socket = io(SOCKET_URL);

        socket.on(EVENT_TOPIC, (eventMessage: EventMessage) => {
            if (onEvent) onEvent(eventMessage);
        });

        return () => {
            socket.disconnect();
        };
    }, [onEvent]);
};

export default useSocket;
