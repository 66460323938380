import { DefaultText } from 'components/_universal/Typography.styled';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ProfileDropdownWrapper = styled.div(
    () => css`
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 12px;
        height: 80px;
        justify-content: space-between;
    `,
);

export const ProfileImage = styled.img(
    ({ theme: { colors } }) =>
        css`
            border-radius: 100%;
            border: 1px solid ${colors.white};
            height: 54px;
            width: 54px;
        `,
);

export const ProfileDropdownTextWrapper = styled(DefaultText)(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.white};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f10};
            font-weight: ${fontWeight.regular};
            text-transform: uppercase;
        `,
);

export const ProfileDropdownTitle = styled(DefaultText)(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.white};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f10};
            font-weight: ${fontWeight.regular};
            text-transform: uppercase;
        `,
);

export const ProfileDropdownName = styled(DefaultText)(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.white};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.medium};
            text-transform: none;
        `,
);

export const ProfileDropdownListWrapper = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.app_bg};
        border-radius: 10px;
        box-shadow: 0px 10px 15px rgba(84, 84, 84, 0.3);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        min-width: 210px;
        overflow: auto;
        position: absolute;
        top: 72px;
    `,
);

export const repeatableStyles = css`
    display: flex;
    gap: 22px;
    padding: 18px;
    text-decoration: none;
    font-size: 12px;
    align-items: center;
    font-weight: 600;
    color: ${({ theme: { colors } }) => colors.text};

    &:hover {
        background: ${({ theme: { colors } }) => colors.linkHover};
    }
`;

export const ProfileDropdownListItem = styled(Link)(
    () =>
        css`
            ${repeatableStyles}
        `,
);

export const ProfileDropdownLogutItem = styled.div(
    () =>
        css`
            ${repeatableStyles}
        `,
);
