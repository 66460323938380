import { useForm, FieldValues } from 'react-hook-form';
import { useState } from 'react';
import { validation } from './validations';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    FormTitle,
    FormWrapper,
    FromSubTitle,
    ResetPasswordPageBox,
    ResetPasswordPageWrapper,
    StyledButton,
    StyledInput,
} from './ResetPassword.styled';

export const ResetPassword = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm<FieldValues>({
        mode: 'all',
        // resolver: yupResolver(validation),
    });
    const onSubmit = () => {
        setIsSubmitting(true);
    };

    return (
        <ResetPasswordPageWrapper>
            <ResetPasswordPageBox>
                <FormTitle>AI Voice</FormTitle>
                <FromSubTitle>ZRESETUJ HASŁO</FromSubTitle>
                <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                    <StyledInput
                        control={control}
                        errors={errors.email}
                        name={'email'}
                        placeholder={'Email'}
                        type={'text'}
                        marginBottom={30}
                        iconType={'Email'}
                    />
                    <StyledButton onPress={() => undefined}>
                        {isSubmitting
                            ? 'resetowanie hasła...'
                            : 'zresetuj hasło'}
                    </StyledButton>
                </FormWrapper>
            </ResetPasswordPageBox>
        </ResetPasswordPageWrapper>
    );
};
