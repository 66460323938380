import styled, { css } from 'styled-components';

export const HeaderTitle = styled.div(
    ({ theme: { fontFamily, fontSizes, fontWeight } }) => css`
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f13};
        font-weight: ${fontWeight.regular};
        line-height: 30px;
        text-align: left;
    `,
);

export const SearchInsideWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const SearchInput = styled.input(
    ({ theme: { colors } }) =>
        css`
            border-radius: 4px;
            border: 1px solid ${colors.tableHeaderBorder};
            padding: 6px 10px;
            width: 145px;
        `,
);

export const SearchIconWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background: ${colors.white};
            position: absolute;
            right: 2px;
        `,
);
