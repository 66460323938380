import styled from 'styled-components';

export const Holder = styled.div<{
    center?: boolean;
    cursorPointer?: boolean;
    rotate?: number;
    size?: number;
}>`
    ${({ center }) => center && `align-self: center;`};
    ${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`};
    ${({ cursorPointer }) => `cursor: ${cursorPointer ? 'pointer' : 'auto'}`};
    display: flex;
    font-size: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    justify-content: center;
    transition: all 0.5s ease-in-out;
`;
