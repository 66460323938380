import { useGoogleLogin } from '@react-oauth/google';
import { notify } from 'components/ToastNotification/ToastNotification';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useHistory } from 'react-router-dom';
import LocalStorageManager from 'services/LocalStorageManager/LocalStorageManager';
import paths from 'shared/paths';
import { useLoginWithGoogleMutation } from 'src/graphql/generated';
import { ToastTypes } from 'types/CommonTypes';
import {
    AnotherLoginMethodText,
    FormTitle,
    FormWrapper,
    FromSubTitle,
    LoginPageBox,
    LoginPageWrapper,
    StyledGoogleButton,
} from './LoginPage.styled';

export const LoginPage = () => {
    const history = useHistory();
    const redirectUri = window.location.origin;

    const [loginWithGoogle, { loading }] = useLoginWithGoogleMutation();

    const login = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (response) => {
            try {
                LocalStorageManager.setValue('O-Auth2-Code', response.code);
                LocalStorageManager.setValue(
                    'X-Redirect-Url',
                    process.env.GOOGLE_REDIRECT_URI ?? redirectUri,
                );
                loginWithGoogle({
                    onCompleted: (data) => {
                        LocalStorageManager.setValue(
                            'X-Session-Token',
                            data.loginWithGoogle,
                        );
                        history.push(paths.home);

                        return notify('Logowanie przebiegło pomyślnie')[
                            ToastTypes.SUCCESS
                        ]();
                    },
                });
            } catch (err) {
                console.log(err);

                return notify('Coś poszło nie tak, error: ' + err)[
                    ToastTypes.ERROR
                ]();
            }
        },
    });

    return (
        <LoginPageWrapper>
            <LoginPageBox>
                <FormTitle>AI Voice</FormTitle>
                <FromSubTitle>ZALOGUJ SIĘ</FromSubTitle>
                <FormWrapper>
                    {/* <StyledInput
                        control={control}
                        errors={errors.email}
                        name={'email'}
                        placeholder={'Email'}
                        type={'text'}
                        marginBottom={30}
                        iconType={'Email'}
                    />
                    <StyledInput
                        control={control}
                        errors={errors.password}
                        name={'password'}
                        placeholder={'Hasło'}
                        type={'password'}
                        iconType={'Password'}
                    />
                    <ForgotPasswordText to={paths.reset_password}>
                        Zapomniałeś hasła?
                    </ForgotPasswordText>
                    <StyledLoginButton onPress={() => undefined}>
                        {isSubmitting ? 'logowanie się...' : 'zaloguj się'}
                    </StyledLoginButton> */}
                    <AnotherLoginMethodText>
                        możesz kontynuować za pomocą
                    </AnotherLoginMethodText>

                    <StyledGoogleButton
                        disabled={loading}
                        buttonType={'grey'}
                        onPress={login}
                    >
                        <IconManager cursorPointer name={'GoogleLoginLogo'} />
                    </StyledGoogleButton>
                </FormWrapper>
            </LoginPageBox>
        </LoginPageWrapper>
    );
};
