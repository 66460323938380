import { SubTitle, Title } from 'components/_universal/Typography.styled';
import { Modal } from 'components/Modal/Modal';
import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
    padding: 30px 33px;
    text-align: center;
`;

export const CorrectText = styled.span`
    color: ${({ theme }) => theme.colors.primary};
`;

export const IncorrectText = styled.span`
    color: ${({ theme }) => theme.colors.error};
`;

export const ModalTitle = styled(Title)(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.text};
            font-size: ${fontSizes.f24};
            font-weight: ${fontWeight.regular};
            text-transform: uppercase;
        `,
);

export const ModalSubTitle = styled(SubTitle)(
    ({ theme: { colors, fontSizes } }) =>
        css`
            color: ${colors.text};
            font-size: ${fontSizes.f16};
        `,
);

export const StyledModal = styled(Modal)`
    height: 810px;
`;

export const CommentWrapper = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 572px;
    width: 100%;
`;

export const CommentButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 12px;
    min-height: 39px;
`;

export const StatusWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            align-items: center;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            border: 1px solid ${colors.borderSecondary};
            border-top: 0px;
            display: flex;
            justify-content: center;
            margin-top: -12px;
            padding: 40px 20px;
            position: relative;
            width: 572px;
        `,
);

export const UndoButtonWrapper = styled.div`
    position: absolute;
    right: 20px;
    bottom: 12px;
    display: flex;
`;
