import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
    Control,
    FieldError,
    FieldErrors,
    useController,
} from 'react-hook-form';
import { IconName } from 'types/CommonTypes';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import {
    EyeIconWrapper,
    IconWrapper,
    Input,
    InputLabel,
    InputWrapper,
} from './TextInput.styled';

export interface TextInputProps {
    control: Control<any, any>;
    disabled?: boolean;
    errors?: FieldError | FieldErrors;
    iconType?: IconName;
    label?: string;
    marginBottom?: number;
    name: string;
    onClick?: () => void;
    placeholder?: string;
    type: 'text' | 'email' | 'password';
}

export const TextInput = ({
    control,
    disabled,
    errors,
    iconType,
    label,
    marginBottom,
    name,
    onClick,
    placeholder,
    type,
}: TextInputProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });

    return (
        <InputWrapper marginBottom={marginBottom} isError={!!errors}>
            {iconType && (
                <IconWrapper>
                    <IconManager cursorPointer name={iconType} size={24} />
                </IconWrapper>
            )}
            <Input
                disabled={disabled}
                id={name}
                isDisabled={disabled}
                isError={!!errors}
                isIcon={!!iconType}
                name={name}
                onChange={onChange}
                onClick={onClick}
                onFocus={onClick}
                placeholder={placeholder}
                type={
                    type === 'password'
                        ? isPasswordVisible
                            ? 'text'
                            : 'password'
                        : type
                }
                value={value}
            />
            {type === 'password' && (
                <EyeIconWrapper
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                    <IconManager
                        cursorPointer
                        name={isPasswordVisible ? 'SlashEye' : 'Eye'}
                        size={24}
                    />
                </EyeIconWrapper>
            )}
            {label && <InputLabel>{label}</InputLabel>}
            {errors && (
                <Fade direction="up" triggerOnce>
                    <ErrorMessage error={errors} />
                </Fade>
            )}
        </InputWrapper>
    );
};
