import { IAppState } from 'types/redux/app';

export const initialState = (): IAppState => ({
    audioUrl: null,
    formData: null,
    isTrascriptionModal: null,
    modalFormIsOpen: false,
    recordingId: null,
    recordingInfo: null,
    filters: {
        audioRecordingIsOpen: false,
        audioRecordingValue: 'all',
        audioStatusIsOpen: false,
        audioStatusValue: 'all',
        dateIsOpen: false,
        dateValue: 'desc',
        searchIsOpen: false,
        searchValue: null,
        transcriptionStatusIsOpen: false,
        transcriptionStatusValue: 'all',
    },
});
