import styled from 'styled-components';

export const PlayerWrapper = styled.div`
    align-items: center;
    background: rgba(245, 245, 245, 1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;
    min-height: 162px;
    position: relative;
    width: 572px;
`;

export const PlayButton = styled.img`
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 5px 5px 15px 0 rgba(198, 198, 198, 0.49);
    border-radius: 50%;
    height: 74px;
    width: 74px;
    cursor: pointer;
    user-select: none;
`;

export const VolumeContainer = styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    gap: 8px;
`;

export const InputVolume = styled.input`
    /* Base styles */
    -webkit-appearance: none;
    width: 76px;
    height: 20px;
    border-radius: 5px;
    outline: none;
    background: none;

    /* Track styles */

    ::-webkit-slider-runnable-track {
        height: 3px;
        background: rgba(217, 217, 217, 1);
        border-radius: 5px;
    }

    /* Thumb styles */

    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 9px;
        height: 9px;
        margin-top: -3px;
        border-radius: 50%;
        background: rgba(0, 196, 185, 1);
        cursor: pointer;
    }
`;

export const WaveDurationContainer = styled.div`
    width: 354px;
    display: flex;
    flex-direction: column;
    margin-top: 48px;
`;

export const DurationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const DurationText = styled.p`
    font-size: 12px;
    color: black;
`;
