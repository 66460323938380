import React, { useState } from 'react';
import { ButtonTypes, ColorType, IconName } from 'types/CommonTypes';
import { StyledButton, StyledIconManager } from './Button.styled';

interface IProps {
    buttonType?: ButtonTypes;
    children?: any;
    className?: string;
    disabled?: boolean;
    onPress?: () => void;
    style?: React.CSSProperties;
    type?: 'button' | 'submit' | 'reset';
    icon?: {
        type: IconName;
        color?: ColorType[];
        activeColor?: ColorType[];
    };
}

export const Button: React.FC<IProps> = ({
    buttonType = 'primary',
    children,
    className,
    disabled,
    icon,
    onPress,
    type,
}) => {
    const [active, setActive] = useState(false);

    return (
        <StyledButton
            buttonType={buttonType}
            className={className}
            disabled={!!disabled}
            icon={!!icon?.type}
            isChildren={!!children}
            onClick={onPress}
            onMouseOut={() => setActive(false)}
            onMouseOver={() => setActive(true)}
            type={type}
        >
            {icon && (
                <StyledIconManager
                    activeFill={icon.activeColor}
                    fill={icon.color}
                    isActive={active}
                    name={icon.type}
                />
            )}
            {children && children}
        </StyledButton>
    );
};
