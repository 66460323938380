import {
    Checkmark,
    FilterInput,
    FilterOption,
    FilterWrapper,
} from './Filters.styled';

interface FilterOptionType {
    label: string;
    value: string;
}

export interface IProps {
    id: string;
    onFilterChange: (value: any) => void;
    options: FilterOptionType[];
    selectedFilter: string;
}

export const Filters = ({
    id,
    onFilterChange,
    options,
    selectedFilter,
}: IProps) => {
    return (
        <FilterWrapper>
            {options.map((option) => (
                <FilterOption key={option.value}>
                    {option.label}
                    <FilterInput
                        checked={selectedFilter === option.value}
                        id={id + option.value}
                        name={id + option.value}
                        type="radio"
                        value={option.value}
                        onChange={() => onFilterChange(option.value)}
                    />
                    <Checkmark className="checkmark" />
                </FilterOption>
            ))}
        </FilterWrapper>
    );
};
