import { AuthLayout } from 'components/_layouts/Auth/AuthLayout';
import { RouterConfig } from '..';
import Error404Page from 'domains/Error404Page/Error404Page';

export const commonRoutes: RouterConfig = [
    {
        type: 'invalid',
        routes: [
            {
                component: Error404Page,
                path: '*',
            },
        ],
        layoutComponent: AuthLayout,
    },
];
