import styled, { css } from 'styled-components';

export const FiltersWrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 4px;
`;

export const HeaderTitle = styled.div(
    ({ theme: { fontFamily, fontSizes, fontWeight } }) => css`
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f13};
        font-weight: ${fontWeight.regular};
        line-height: 30px;
        text-align: left;
    `,
);

export const FiltersDropdown = styled.div<{ filtersVisible: boolean }>(
    ({ theme: { colors }, filtersVisible }) =>
        css`
            background: ${colors.white};
            border-radius: 0px 0px 8px 8px;
            opacity: ${filtersVisible ? 1 : 0};
            padding: 12px;
            position: absolute;
            top: ${filtersVisible ? 55 : -100}px;
            transition: all 0.5s ease-in;
            z-index: 101;
        `,
);

export const FilterIndicator = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primary};
        border-radius: 100%;
        height: 5px;
        width: 5px;
    `,
);
