import { Button } from 'components/_universal/Button/Button';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Title, DefaultText } from 'components/_universal/Typography.styled';
import styled, { css } from 'styled-components';

export const Error404PageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 640px;
`;

export const StyledIconManager = styled(IconManager)`
    margin-bottom: 66px;
`;

export const StyledTitle = styled(Title)`
    margin-bottom: 40px;
    text-align: center;
`;

export const Description = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeight } }) => css`
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.bold};
        margin-bottom: 48px;
        text-align: center;
    `,
);

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledButton = styled(Button)`
    min-width: 323px;
`;
