import { IconManager } from 'components/_universal/IconManager/IconManager';
import React from 'react';
import {
    List,
    ListItem,
    SectionSubtitle,
    StyledInput,
    SymptomsInnerWrapper,
} from '../../Form.styled';
import { PreviousConditionsFields } from '../../types';
import {
    BlueBackground,
    RemoveButtonWrapper,
    SymptomsWrapper,
} from '../Styles';

interface NewPreviousConditionProps {
    index: number;
    condition: PreviousConditionsFields;
    onChange: (
        index: number,
        field: keyof PreviousConditionsFields | 'medications',
        value: any,
    ) => void;
    onRemove: (index: number) => void;
    isEdit: boolean;
}

const NewPreviousCondition: React.FC<NewPreviousConditionProps> = ({
    index,
    condition,
    onChange,
    onRemove,
    isEdit,
}) => {
    const handleMedicationChange = (medIndex: number, value: string) => {
        const newMedications = condition.medications.map((medication, idx) =>
            idx === medIndex ? value : medication,
        );
        if (medIndex === condition.medications.length - 1 && value) {
            newMedications.push('');
        }
        onChange(index, 'medications', newMedications);
    };

    return (
        <SymptomsWrapper isEdit={isEdit}>
            <BlueBackground>
                <SectionSubtitle>
                    {isEdit ? (
                        <StyledInput
                            placeholder="Dodaj nazwę choroby"
                            value={condition.condition}
                            onChange={(e) =>
                                onChange(index, 'condition', e.target.value)
                            }
                        />
                    ) : (
                        condition.condition
                    )}
                </SectionSubtitle>
                <SectionSubtitle>
                    {isEdit ? (
                        <StyledInput
                            placeholder="Dodaj opis choroby"
                            value={condition.description}
                            onChange={(e) =>
                                onChange(index, 'description', e.target.value)
                            }
                        />
                    ) : (
                        condition.description
                    )}
                </SectionSubtitle>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Leki i dawkowanie</SectionSubtitle>
                    <List>
                        {condition.medications.map((medication, medIdx) => (
                            <ListItem key={medIdx}>
                                {isEdit ? (
                                    <StyledInput
                                        displayInlineFlex
                                        placeholder="Dodaj nazwę leku"
                                        value={medication}
                                        onChange={(e) =>
                                            handleMedicationChange(
                                                medIdx,
                                                e.target.value,
                                            )
                                        }
                                    />
                                ) : (
                                    `${medication}`
                                )}
                            </ListItem>
                        ))}
                    </List>
                </SymptomsInnerWrapper>
            </BlueBackground>

            {isEdit && (
                <RemoveButtonWrapper>
                    <IconManager
                        onClick={() => onRemove(index)}
                        name={'TrashIcon'}
                    />
                </RemoveButtonWrapper>
            )}
        </SymptomsWrapper>
    );
};

export default NewPreviousCondition;
