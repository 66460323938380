import * as yup from 'yup';

export const errorMessages = {
    INVALID_EMAIL_FORMAT:
        'Invalid e-mail address format. Please enter in format name@codahead.com',
    REQUIRED_EMAIL: 'Email is required',
    REQUIRED_NAME: 'Name is required',
    REQUIRED_PASSWORD: 'Password is required',
    NAME_MAX_LENGTH: 'Imię i nazwisko nie może być dłuższe niż 50 znaków',
};

export const emailValidation = yup
    .string()
    .email(errorMessages.INVALID_EMAIL_FORMAT)
    .required(errorMessages.REQUIRED_EMAIL);

export const passwordValidation = yup
    .string()
    .required(errorMessages.REQUIRED_PASSWORD);

export const nameValidation = yup
    .string()
    .required(errorMessages.REQUIRED_NAME)
    .max(50, errorMessages.NAME_MAX_LENGTH);

export const validation = yup.object().shape({
    // email: emailValidation,
    name: nameValidation,
    // password: passwordValidation,
});
