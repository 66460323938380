import React from 'react';
import { SectionSubtitle, StyledInput, SectionText } from '../../Form.styled';
import { DrugsFields } from 'domains/Form/types';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import {
    BlueBackground,
    RemoveButtonWrapper,
    SymptomsWrapper,
    SymptomsInnerWrapper,
} from '../Styles';

interface NewDrugProps {
    index: number;
    drug: DrugsFields;
    onChange: (index: number, field: string, value: any) => void;
    onRemove: (index: number) => void;
    isEdit: boolean;
}

const NewDrug: React.FC<NewDrugProps> = ({
    index,
    drug,
    onChange,
    onRemove,
    isEdit,
}) => {
    return (
        <SymptomsWrapper isEdit={isEdit}>
            <BlueBackground>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Nazwa używki</SectionSubtitle>
                    <SectionText>
                        {isEdit ? (
                            <StyledInput
                                value={drug.name}
                                onChange={(e) =>
                                    onChange(index, 'name', e.target.value)
                                }
                            />
                        ) : (
                            drug.name
                        )}
                    </SectionText>
                </SymptomsInnerWrapper>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Częstotliwość</SectionSubtitle>
                    <SectionText>
                        {isEdit ? (
                            <StyledInput
                                value={drug.frequency}
                                onChange={(e) =>
                                    onChange(index, 'frequency', e.target.value)
                                }
                            />
                        ) : (
                            drug.frequency
                        )}
                    </SectionText>
                </SymptomsInnerWrapper>
            </BlueBackground>

            {isEdit && (
                <RemoveButtonWrapper>
                    <IconManager
                        onClick={() => onRemove(index)}
                        name={'TrashIcon'}
                    />
                </RemoveButtonWrapper>
            )}
        </SymptomsWrapper>
    );
};

export default NewDrug;
