import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useSelector } from 'react-redux';
import { VerificationStatus } from 'src/graphql/generated';
import { selectFilters, setFilters } from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { Filters } from '../Filters/Filters';
import {
    FilterIndicator,
    FiltersDropdown,
    FiltersWrapper,
    HeaderTitle,
} from '../FiltersShared.styled';

export const AudioStatusFilters = () => {
    const selectedFilters = useSelector(selectFilters);
    const audioStatusIsOpen = selectedFilters?.audioStatusIsOpen;
    const selectedFilter = selectedFilters?.audioStatusValue;
    const filterIsActive = selectedFilter !== 'all';

    const filterOptions = [
        { label: 'wszystkie', value: 'all' },
        { label: 'zatwierdzone', value: 'ACCEPTED' },
        { label: 'odrzucone', value: 'REJECTED' },
        { label: 'oczekujące', value: 'PENDING' },
    ];

    const handleFilterChange = (filterValue: VerificationStatus) => {
        dispatch(setFilters({ audioStatusValue: filterValue }));
    };

    return (
        <div>
            <FiltersWrapper
                onClick={() => {
                    dispatch(
                        setFilters({
                            audioStatusIsOpen: !audioStatusIsOpen,
                        }),
                    );
                }}
            >
                <HeaderTitle>Status audio</HeaderTitle>
                <IconManager
                    name="Arrow"
                    cursorPointer
                    rotate={audioStatusIsOpen ? 0 : 180}
                />
                {filterIsActive && <FilterIndicator />}
            </FiltersWrapper>
            {audioStatusIsOpen && (
                <FiltersDropdown filtersVisible={audioStatusIsOpen}>
                    <Filters
                        id={'AudioStatus'}
                        onFilterChange={handleFilterChange}
                        options={filterOptions}
                        selectedFilter={selectedFilter}
                    />
                </FiltersDropdown>
            )}
        </div>
    );
};
