import { IRootState } from 'types/redux/redux';

export const selectAudioUrl = (state: IRootState) =>
    state?.app?.audioUrl ?? null;

export const selectRecordingInfo = (state: IRootState) =>
    state?.app?.recordingInfo ?? null;

export const selectIsTranscriptModal = (state: IRootState) =>
    state?.app?.isTrascriptionModal ?? null;

export const selectFilters = (state: IRootState) => state?.app?.filters ?? null;

export const selectModalFormIsOpen = (state: IRootState) =>
    state?.app?.modalFormIsOpen;

export const selectFormData = (state: IRootState) => state?.app?.formData;

export const selectRecordingId = (state: IRootState) => state?.app?.recordingId;
