import { IconManager } from 'components/_universal/IconManager/IconManager';
import paths from 'shared/paths';
import { ProfileDropdown } from '../ProfileDropdown/ProfileDropdown';
import { HeaderLink, HeaderWrapper } from './Header.styled';

export interface IProps {}

export const Header = ({}: IProps) => {
    return (
        <HeaderWrapper>
            <HeaderLink to={paths.home}>
                <IconManager cursorPointer name={'LogoIcon'} />
            </HeaderLink>
            <ProfileDropdown />
        </HeaderWrapper>
    );
};
