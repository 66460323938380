import { IconManager } from 'components/_universal/IconManager/IconManager';
import { AddNewFieldWrapper, SectionTitle } from './AddNewField.styled';

interface IProps {
    toggleEditing?: () => void;
}

export const AddNewField = ({ toggleEditing }: IProps) => (
    <AddNewFieldWrapper>
        <SectionTitle>Dodaj nowe pole</SectionTitle>

        <IconManager
            onClick={toggleEditing}
            name={'AddNewFieldIcon'}
            cursorPointer
        />
    </AddNewFieldWrapper>
);
