import { AuthLayout } from 'components/_layouts/Auth/AuthLayout';
import { LoginPage } from 'domains/Login/LoginPage/LoginPage';
import { ResetPassword } from 'domains/Login/ResetPassword/ResetPassword';
import { RouterConfig } from '..';
import paths from 'shared/paths';

export const authRoutes: RouterConfig = [
    // Authorization Login
    {
        routes: [
            {
                component: LoginPage,
                exact: true,
                path: paths.login,
            },
        ],
        layoutComponent: AuthLayout,
    },
    {
        routes: [
            {
                component: ResetPassword,
                exact: true,
                path: paths.reset_password,
            },
        ],
        layoutComponent: AuthLayout,
    },
];
