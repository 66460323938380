import styled, { css } from 'styled-components';

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0;
`;

export const RadioOption = styled.label(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) => css`
        align-items: center;
        cursor: pointer;
        display: flex;
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeight.regular};
        line-height: 30px;
        margin-right: 16px;

        input[type='radio'] {
            cursor: pointer;
            appearance: none;
            border-radius: 50%;
            height: 20px;
            margin-left: 6px;
            width: 20px;
            border: 1px solid ${colors.placeholder};

            &:before {
                border-radius: 50%;
                content: '';
                cursor: pointer;
                display: inline-block;
                height: 14px;
                left: 2px;
                position: relative;
                top: 2px;
                transition: all 250ms ease;
                width: 14px;
            }

            :checked {
                &:before {
                    background: ${colors.placeholder};
                }
            }
        }
    `,
);
