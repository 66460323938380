import { IconManager } from 'components/_universal/IconManager/IconManager';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { ButtonTypes } from 'types/CommonTypes';

const primaryButton = css<{ disabled: boolean }>(
    ({ theme: { colors, fontFamily }, disabled }) => css`
        background: linear-gradient(
            71.71deg,
            #00c4b9 -5.47%,
            rgba(0, 229, 243, 0) 259.87%
        );
        border: none;
        color: ${colors.app_bg};
        font-family: ${fontFamily.secondary};
        text-transform: uppercase;

        &:hover {
            background-color: ${transparentize(0.4, colors.primary)};
        }

        ${disabled && primaryButtonDisabled}
    `,
);

const primaryButtonDisabled = css(
    ({ theme: { colors } }) => css`
        background: ${colors.primaryButtonDisabled};
        border-color: ${colors.primaryButtonDisabled};
        cursor: auto;

        &:hover {
            background: ${colors.primaryButtonDisabled};
            border-color: ${colors.primaryButtonDisabled};
        }
    `,
);

const secondaryButton = css<{ disabled: boolean }>(
    ({ theme: { colors }, disabled }) => css`
        background-color: transparent;
        border: 1px solid ${colors.secondary};
        color: ${colors.secondary};
        text-transform: uppercase;

        &:hover {
            background-color: ${transparentize(0.96, colors.secondary)};
            border-color: ${transparentize(0.96, colors.secondary)};
        }

        ${disabled && secondaryButtonDisabled}
    `,
);

const secondaryButtonDisabled = css(
    ({ theme: { colors } }) => css`
        border-color: ${colors.disable};
        opacity: 0.4;

        &:hover {
            border-color: ${colors.disable};
        }
    `,
);

const greyButton = css<{ disabled: boolean }>(
    ({ theme: { colors }, disabled }) => css`
        border: none;
        background-color: ${colors.buttonGrey}40;

        &:hover {
            background-color: ${transparentize(0.96, colors.buttonGrey)};
        }

        ${disabled && greyButtonDisabled}
    `,
);

const greyButtonDisabled = css(
    ({ theme: { colors } }) => css`
        background-color: ${colors.buttonGrey};
        opacity: 0.4;

        &:hover {
            background-color: ${colors.disable};
        }
    `,
);

const buttonWithIcon = css`
    align-content: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
`;

const ButtonStyleTypes = {
    primary: primaryButton,
    secondary: secondaryButton,
    grey: greyButton,
};

export const StyledButton = styled.button<{
    buttonType?: ButtonTypes;
    disabled: boolean;
    icon?: boolean;
    isChildren?: boolean;
}>(
    ({
        buttonType,
        icon,
        isChildren,
        theme: { borderRadius, fontSizes, transitions, fontFamily, fontWeight },
    }) => css`
        border-radius: ${borderRadius.button}px;
        cursor: pointer;
        font-size: ${fontSizes.f12};
        min-width: 135px;
        text-align: center;
        transition: all ${transitions.default}s;
        font-family: ${fontFamily.secondary};
        padding: ${isChildren
            ? icon
                ? ' 11px 21px 11px 15px;'
                : '11px 32px'
            : '11px 30px'};

        ${buttonType && ButtonStyleTypes[buttonType]};
        ${icon && buttonWithIcon}

        &:hover,
        &:focus,
        &:active {
            outline: none;
        }
    `,
);

export const StyledIconManager = styled(IconManager)`
    margin-right: 10px;
`;
