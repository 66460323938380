import PauseIcon from 'assets/svgs/PauseIcon.svg';
import PlayIcon from 'assets/svgs/PlayIcon.svg';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Loader } from 'components/_universal/Loader/Loader';
import {
    DurationContainer,
    DurationText,
    InputVolume,
    PlayButton,
    PlayerWrapper,
    VolumeContainer,
    WaveDurationContainer,
} from 'domains/MainPanel/components/AudioPlayer/AudioPlayer.styled';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import WaveSurfer from 'wavesurfer.js';

const formWaveSurferOptions = (
    ref: HTMLElement | string,
    theme: DefaultTheme,
) => ({
    container: ref,
    waveColor: theme.colors.waveBackground,
    progressColor: theme.colors.primary,
    cursorColor: theme.colors.primary,
    barWidth: 3,
    barRadius: 2,
    responsive: true,
    height: 38,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
});

// ex. 12.3495 --> 0:12
const formatWaveTime = (decimalStr: string) => {
    try {
        let decimalNumber = parseFloat(decimalStr);
        let seconds = Math.floor(decimalNumber);
        let minutes = Math.floor(seconds / 60);
        return (
            minutes.toString() +
            ':' +
            (seconds % 60).toString().padStart(2, '0')
        );
    } catch (error) {
        // Handle any errors
        console.error(error);
        return '0:00';
    }
};

interface IAudioPlayerProps {
    url: string;
}

export const AudioPlayer = ({ url }: IAudioPlayerProps) => {
    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);
    const theme = useTheme();

    const [audioReady, setAudioReady] = useState(false);
    const [playing, setPlay] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [currentTime, setCurrentTime] = useState('0:00');
    const [duration, setDuration] = useState('0:00');

    // create new WaveSurfer instance
    // On component mount and when url changes
    useEffect(() => {
        setPlay(false);

        const options = formWaveSurferOptions(
            waveformRef.current as HTMLDivElement,
            theme,
        );

        wavesurfer.current = WaveSurfer.create(options);
        wavesurfer.current.load(url);

        wavesurfer.current.on('ready', function () {
            // make sure object is still available when file loaded
            if (wavesurfer.current) {
                wavesurfer.current.setVolume(volume);
                setVolume(volume);
                setDuration(wavesurfer.current?.getDuration().toString());
                setAudioReady(true);
            }
        });

        wavesurfer.current.on('audioprocess', () => {
            const time = wavesurfer.current?.getCurrentTime();
            setCurrentTime(time?.toString() ?? '');
        });

        wavesurfer.current.on('click', () => {
            const time = wavesurfer.current?.getCurrentTime();
            setCurrentTime(time?.toString() ?? '');
        });

        // Removes events, elements and disconnects Web SpeakerButton nodes.
        // when component unmount
        return () => wavesurfer.current?.destroy();
    }, [url]);

    const handlePlayPause = () => {
        setPlay(!playing);
        wavesurfer.current?.playPause();
    };

    const onVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { target } = e;
        const newVolume = Number(target.value);

        if (newVolume) {
            setVolume(newVolume);
            wavesurfer.current?.setVolume(newVolume || 1);
        }
    };

    return (
        <PlayerWrapper>
            <VolumeContainer>
                <IconManager cursorPointer name="HeadphonesIcon" />
                <InputVolume
                    type="range"
                    id="volume"
                    name="volume"
                    // waveSurfer recognize value of `0` same as `1`
                    //  so we need to set some zero-ish value for silence
                    min="0.01"
                    max="1"
                    step=".025"
                    onChange={onVolumeChange}
                    defaultValue={volume}
                />
            </VolumeContainer>
            {audioReady ? (
                <PlayButton
                    src={playing ? PauseIcon : PlayIcon}
                    onClick={handlePlayPause}
                />
            ) : (
                <Loader />
            )}
            <WaveDurationContainer>
                <div id="waveform" ref={waveformRef} />
                <DurationContainer>
                    <DurationText>{formatWaveTime(currentTime)}</DurationText>
                    <DurationText>{formatWaveTime(duration)}</DurationText>
                </DurationContainer>
            </WaveDurationContainer>
        </PlayerWrapper>
    );
};
