import { IRootState } from 'types/redux/redux';

export const getCommonStateData = (state: IRootState) => {
    // add common selectors here
    const userId = 'mockId';

    return {
        userId,
    };
};
