import React from 'react';
import * as Icons from 'shared/svgs/Icons';
import { ThemeContext } from 'styled-components';
import { ColorType, IconName } from 'types/CommonTypes';
import { Holder } from './IconManager.styled';

interface IProps {
    activeFill?: ColorType[];
    center?: boolean;
    className?: string;
    cursorPointer?: boolean;
    fill?: ColorType[];
    isActive?: boolean;
    name?: IconName;
    onClick?: () => void;
    rotate?: number;
    size?: number;
    sizeY?: number;
}

export const IconManager: React.FC<IProps> = ({
    activeFill,
    center,
    className,
    cursorPointer,
    fill,
    isActive,
    name,
    onClick,
    rotate,
    size,
    sizeY,
}) => {
    const Icon = Icons[name || 'Close'];
    const colors: { [key in ColorType]: string } =
        React.useContext(ThemeContext).colors;
    const iconColor = fill && fill.map((item) => colors[item]);
    const iconActiveColor =
        activeFill && activeFill.map((item) => colors[item]);

    return (
        <Holder
            center={center}
            className={className}
            cursorPointer={cursorPointer}
            onClick={onClick}
            rotate={rotate}
            size={size}
        >
            <Icon fill={isActive ? iconActiveColor : iconColor} sizeY={sizeY} />
        </Holder>
    );
};
