import { TextInput } from 'components/_form-elements/TextInput/TextInput';
import { Button } from 'components/_universal/Button/Button';
import { DefaultText } from 'components/_universal/Typography.styled';
import styled, { css } from 'styled-components';

export const ProfilePageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
`;

export const ProfileImageWrapper = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        border-bottom: 1px solid ${colors.profileBorder};
        display: flex;
        flex-direction: column;
        margin-bottom: 26px;
        max-width: 728px;
        padding: 44px 0;
        position: relative;
        width: 100%;
    `,
);

export const ProfileImageInputWrapper = styled.div`
    position: relative;
`;

export const ProfileImageInput = styled.input`
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
`;

export const ProfileImage = styled.img(
    ({ theme: { colors } }) =>
        css`
            border-radius: 100%;
            border: 1px solid ${colors.profileImageBorder};
            height: 100px;
            margin-bottom: 12px;
            width: 100px;
        `,
);

export const ProfileTitle = styled(DefaultText)(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) => css`
        color: ${colors.primary};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f10};
        font-weight: ${fontWeight.regular};
        text-transform: uppercase;
    `,
);

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 354px;
    width: 100%;
`;

export const StyledInput = styled(TextInput)``;

export const StyledButton = styled(Button)(
    ({ theme: { fontSizes } }) => css`
        align-self: flex-end;
        font-size: ${fontSizes.f12};
        margin-top: 16px;
        max-width: 141px;
    `,
);
