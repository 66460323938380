import { notify } from 'components/ToastNotification/ToastNotification';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Tooltip } from 'components/_universal/Tooltip/Tooltip';
import { RotatingLines } from 'react-loader-spinner';
import {
    useGetReportLazyQuery,
    useGetReportUrlLazyQuery,
    useRequestReportMutation,
} from 'src/graphql/generated';
import { ToastTypes } from 'types/CommonTypes';
import { FormWrapper } from './Form.styled';
import { useEffect } from 'react';
import { dispatch } from 'state/redux/store';
import { setFormData, setRecordingId } from 'state/redux/slices/app';
import useRemoveTypename from 'utils/hooks/useRemoveTypename';

export interface IProps {
    hasReport: boolean;
    isReportQueued: boolean;
    recordingId: number;
}

export const Form = ({ recordingId, hasReport, isReportQueued }: IProps) => {
    const [getReportUrl] = useGetReportUrlLazyQuery({
        variables: {
            recordingId: recordingId,
        },
        onCompleted: (data) => {
            if (data && data?.getReportUrl) {
                window.open(data?.getReportUrl, '_blank');
            }
        },
        onError: (error) => {
            return notify('Błąd ściągania formularza: ' + error.message)[
                ToastTypes.ERROR
            ]();
        },
    });

    const [getReport, { data }] = useGetReportLazyQuery({
        variables: { recordingId: recordingId },
        onError: (error) => {
            return notify('Błąd ściągania formularza: ' + error.message)[
                ToastTypes.ERROR
            ]();
        },
    });

    const cleanedData = useRemoveTypename(data?.getReport);

    const [requestReport] = useRequestReportMutation({
        variables: {
            recordingId: recordingId,
        },
        onError: (error) => {
            return notify('Błąd generowania formularza: ' + error.message)[
                ToastTypes.ERROR
            ]();
        },
        refetchQueries: 'active',
    });

    const handleDownloadReport = () => {
        getReportUrl();
    };

    const handleOpenForm = () => {
        dispatch(setFormData(cleanedData));
        dispatch(setRecordingId(recordingId));
    };

    useEffect(() => {
        getReport();
    }, []);

    return (
        <FormWrapper>
            {!hasReport ? (
                isReportQueued ? (
                    <Tooltip text={'Formularz jest generowany'}>
                        <RotatingLines
                            strokeColor="#545454"
                            strokeWidth="2.5"
                            width="26.5"
                        />
                    </Tooltip>
                ) : (
                    <Tooltip text={'Generuj formularz'}>
                        <IconManager
                            cursorPointer
                            name={'GenerateIcon'}
                            onClick={() => requestReport()}
                        />
                    </Tooltip>
                )
            ) : (
                <Tooltip text={'Ściągnij formularz'}>
                    <IconManager
                        cursorPointer
                        name={'TableFormIcon'}
                        onClick={handleDownloadReport}
                    />
                </Tooltip>
            )}
            <Tooltip text={'Otwórz modal z formularzem'}>
                <IconManager
                    cursorPointer
                    name={'TableFormIcon'}
                    onClick={handleOpenForm}
                />
            </Tooltip>
        </FormWrapper>
    );
};
