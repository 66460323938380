import { createListenerMiddleware } from '@reduxjs/toolkit';
import { exceptionListeners } from 'state/redux/middlewares/exceptionListeners';
import { appListeners } from 'state/redux/slices/app/appListeners';
import { TAppStartListening } from 'types/redux/redux';
import { getCommonStateData } from 'utils/getCommonStateData';

export const listenerMiddleware = createListenerMiddleware({
    extra: { getCommonStateData },
});

export const startAppListening =
    listenerMiddleware.startListening as TAppStartListening;

const listeners = [...appListeners, ...exceptionListeners];

listeners.forEach((listener) => {
    startAppListening(listener as never);
});
