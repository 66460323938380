import styled, { keyframes } from 'styled-components';

export const ModalFadeIn = keyframes`
    from{
        opacity: 0
    }
    to{
        opacity: 1
    }
`;

export const ModalWrapper = styled.div`
    animation: ${ModalFadeIn} 0.5s forwards;
    background: rgba(84, 84, 84, 0.42);
    height: 100vh;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    will-change: opacity;
    z-index: 999;
`;

export const Content = styled.div`
    /* justify-content: space-between; */
    align-items: center;
    background: white;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    margin: 32px auto;
    max-width: 960px;
    position: relative;
`;
