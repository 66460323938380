import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'components/ToastNotification/ToastNotification';
import { validation } from 'domains/Login/LoginPage/validations';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import LocalStorageManager from 'services/LocalStorageManager/LocalStorageManager';
import paths from 'shared/paths';
import {
    useAuthMeLazyQuery,
    useUpdateUserMutation,
} from 'src/graphql/generated';
import { ToastTypes } from 'types/CommonTypes';
import {
    FormWrapper,
    ProfileImage,
    ProfileImageInput,
    ProfileImageInputWrapper,
    ProfileImageWrapper,
    ProfilePageWrapper,
    ProfileTitle,
    StyledButton,
    StyledInput,
} from './ProfilePage.styled';

type FormFields = {
    email?: string;
    name: string;
    // password: string;
};

export const ProfilePage = () => {
    const history = useHistory();
    const [file, setFile] = useState<File | null>(null);

    const [getAuthMe, { data }] = useAuthMeLazyQuery({
        onError: (error) => {
            if (error.message === 'Session not found') {
                history.push(paths.login);
                LocalStorageManager.clear();
            }
        },
    });
    const user = data?.me;
    const [updateUser] = useUpdateUserMutation();

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
        watch,
    } = useForm<FormFields>({
        mode: 'all',
        resolver: yupResolver(validation),
    });

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        selectedFile && setFile(selectedFile);

        if (selectedFile) {
            notify('Pomyślnie wgrano plik, zapisz swoje ustawienia !')[
                ToastTypes.SUCCESS
            ]();
        }
    };

    const onSubmit = async () => {
        try {
            await updateUser({
                variables: {
                    input: { name: watch('name'), avatar: file },
                },
                onCompleted: () =>
                    notify('Aktualizacja użytkownika przebiegła pomyślnie')[
                        ToastTypes.SUCCESS
                    ](),
            });
        } catch (error) {
            return notify('Błąd podczas aktualizacji użytkownika: ' + error)[
                ToastTypes.ERROR
            ]();
        }
    };

    useEffect(() => {
        getAuthMe();
    }, []);

    useEffect(() => {
        setValue('email', user?.email ?? '');
        setValue('name', user?.name ?? '');
    }, [user]);

    return (
        <ProfilePageWrapper>
            <ProfileImageWrapper>
                <ProfileImageInputWrapper>
                    {user?.avatar && <ProfileImage src={user?.avatar} />}
                    <ProfileImageInput
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </ProfileImageInputWrapper>
                <ProfileTitle>{user?.role}</ProfileTitle>
            </ProfileImageWrapper>
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <StyledInput
                    control={control}
                    errors={errors.name}
                    label={'Imię i nazwisko'}
                    marginBottom={8}
                    name={'name'}
                    type={'text'}
                />
                <StyledInput
                    control={control}
                    disabled={true}
                    errors={errors.email}
                    label={'Email'}
                    marginBottom={8}
                    name={'email'}
                    type={'text'}
                />
                {/* <StyledInput
                    control={control}
                    disabled={isEdited}
                    errors={errors.password}
                    label={'Hasło'}
                    name={'password'}
                    type={'password'}
                /> */}
                <StyledButton type="submit">
                    {isSubmitting ? 'zapisywanie...' : 'zapisz'}
                </StyledButton>
            </FormWrapper>
        </ProfilePageWrapper>
    );
};
