import * as yup from 'yup';

export const errorMessages = {
    REQUIRED_COMMENT: 'Komentarz jest wymagany',
    MAX_LENGTH: 'Maksymalna długość komentarza to 500 znaków',
};

export const commentValidation = yup
    .string()
    .required(errorMessages.REQUIRED_COMMENT)
    .max(500, errorMessages.MAX_LENGTH);

export const validation = yup.object().shape({
    comment: commentValidation,
});
