import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background: ${colors.textAreaBg};
            border-radius: 20px;
            padding: 16px;
            display: flex;
            gap: 16px;
            align-items: center;
        `,
);

export const BoxNumber = styled.p(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.regular};
    `,
);

export const SelectsWrapper = styled.div(
    () =>
        css`
            display: flex;
            flex-direction: column;
            gap: 12px;
        `,
);

export const BadQualityWrapper = styled.div(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
        css`
            align-items: center;
            color: ${colors.text};
            cursor: pointer;
            display: flex;
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.light};
            gap: 12px;
        `,
);

export const BadQualityItem = styled.div<{ isLowQuality: boolean }>(
    ({ theme: { colors }, isLowQuality }) =>
        css`
            align-items: center;
            border: 2px solid
                ${isLowQuality ? colors.inputBorder : colors.profileImageBorder};
            border-radius: 50%;
            display: flex;
            height: 33px;
            justify-content: center;
            width: 33px;
        `,
);
