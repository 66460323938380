import { isRejected, AnyAction } from '@reduxjs/toolkit';
import {
    ERROR_REDUX_REJECTED,
    ERROR_REDUX_ABORTED,
    ERROR_AXIOS_CANCELED,
} from 'shared/constants';
import { TAppListenerAPI } from 'types/redux/redux';
const ERROR_SYSTEM_DEFAULTS = new Set([
    ERROR_AXIOS_CANCELED,
    ERROR_REDUX_ABORTED,
    ERROR_REDUX_REJECTED,
]);

const ignoredActions = (arg: any) => false;
// const ignoredActions = isAnyOf(exampleReducerAction.rejected);

// Matching all actions and listens for exceptions. In matcher you can put ignored actions as well.
export const exceptionListeners = [
    {
        matcher: (action: AnyAction) =>
            !ignoredActions(action) ? isRejected(action) : false,
        effect: (
            action: { error: { message: string } },
            { dispatch }: TAppListenerAPI,
        ) => {
            const message = action?.error?.message ?? '';

            if (!ERROR_SYSTEM_DEFAULTS.has(message)) {
                // handle notification or sth
                console.log(message);
            }
        },
    },
];
