import { PayloadAction } from '@reduxjs/toolkit';
import { IAppState, filtersType, recordingInfoType } from 'types/redux/app';

export const reducers = {
    setAudioUrl: (
        state: IAppState,
        action: PayloadAction<string | null>, // label used in listener
    ) => {
        state.audioUrl = action.payload;
    },

    setRecordingInfo: (
        state: IAppState,
        action: PayloadAction<recordingInfoType | null>,
    ) => {
        state.recordingInfo = action.payload;
    },

    setIsTrascriptionModal: (
        state: IAppState,
        action: PayloadAction<boolean | null>,
    ) => {
        state.isTrascriptionModal = action.payload;
    },

    setFilters: (state: IAppState, action: PayloadAction<filtersType>) => {
        state.filters = {
            ...state.filters,
            ...action.payload,
        };
    },

    setModalFormIsOpen: (state: IAppState, action: PayloadAction<boolean>) => {
        state.modalFormIsOpen = action.payload;
    },

    setFormData: (state: IAppState, action: PayloadAction<any>) => {
        state.formData = action.payload;
    },

    setRecordingId: (state: IAppState, action: PayloadAction<number>) => {
        state.recordingId = action.payload;
    },
};
