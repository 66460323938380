import paths from 'shared/paths';
import { MenuLink, MenuWrapper } from './Menu.styled';
import { useLocation } from 'react-router-dom';

export interface IProps {}

export const Menu = ({}: IProps) => {
    const location = useLocation();

    return (
        <MenuWrapper>
            <MenuLink to={paths.home} active={location.pathname === paths.home}>
                Dashoard
            </MenuLink>
            <MenuLink
                to={paths.record}
                active={location.pathname === paths.record}
            >
                Nagraj
            </MenuLink>
        </MenuWrapper>
    );
};
