import styled, { css } from 'styled-components';

export const TextareaInput = styled.textarea(
    ({
        theme: { colors, fontFamily, fontSizes, fontWeight, borderRadius },
    }) => css`
        background: ${colors.app_bg};
        border-radius: ${borderRadius.textarea}px;
        border: 2px solid ${colors.error};
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f12};
        font-weight: ${fontWeight.light};
        outline: none;
        padding: 16px 60px 16px 16px;
        width: 100%;
        resize: none;
        min-height: 162px;

        &::-webkit-input-placeholder,
        &::-ms-input-placeholder {
            color: ${colors.placeholder};
        }
    `,
);

export const TextareaWrapper = styled.div<{
    isError?: boolean;
}>(
    ({ isError }) => css`
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin-bottom: ${isError ? '6px' : '0'};
    `,
);

export const TextareaSendIconWrapper = styled.div`
    position: absolute;
    right: 16px;
    bottom: 12px;
    z-index: 1;
`;
