import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useSelector } from 'react-redux';
import { FileTypeFilters } from 'src/graphql/generated';
import { selectFilters, setFilters } from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { Filters } from '../Filters/Filters';
import {
    FiltersDropdown,
    FilterIndicator,
    FiltersWrapper,
    HeaderTitle,
} from '../FiltersShared.styled';

export const AudioRecordingFilters = () => {
    const selectedFilters = useSelector(selectFilters);
    const audioRecordingIsOpen = selectedFilters?.audioRecordingIsOpen;
    const selectedFilter = selectedFilters?.audioRecordingValue;
    const filterIsActive = selectedFilter !== 'all';

    const filterOptions = [
        { label: 'wszystkie', value: 'all' },
        { label: 'm4a', value: 'm4a' },
        { label: 'mp3', value: 'mp3' },
        { label: 'wav', value: 'wav' },
    ];

    const handleFilterChange = (filterValue: FileTypeFilters) => {
        dispatch(setFilters({ audioRecordingValue: filterValue }));
    };

    return (
        <div>
            <FiltersWrapper
                onClick={() => {
                    dispatch(
                        setFilters({
                            audioRecordingIsOpen: !audioRecordingIsOpen,
                        }),
                    );
                }}
            >
                <HeaderTitle>Nagranie audio</HeaderTitle>
                <IconManager
                    name="Arrow"
                    cursorPointer
                    rotate={audioRecordingIsOpen ? 0 : 180}
                />
                {filterIsActive && <FilterIndicator />}
            </FiltersWrapper>
            {audioRecordingIsOpen && (
                <FiltersDropdown filtersVisible={audioRecordingIsOpen}>
                    <Filters
                        onFilterChange={handleFilterChange}
                        id={'AudioRecording'}
                        options={filterOptions}
                        selectedFilter={selectedFilter}
                    />
                </FiltersDropdown>
            )}
        </div>
    );
};
