import { useEffect } from 'react';
import { useTheme } from 'src/App/ThemeContext';
import { Content, ModalWrapper } from './Modal.styled';

export interface IProps {
    children: any;
    className?: string;
}

export const Modal = ({ children, className }: IProps) => {
    const { setModalIsOpen } = useTheme();

    useEffect(() => {
        setModalIsOpen(true);

        return () => {
            setModalIsOpen(false);
        };
    }, []);

    return (
        <ModalWrapper>
            <Content className={className}>{children}</Content>
        </ModalWrapper>
    );
};
