import { MainLayout } from 'components/_layouts/General/MainLayout';
import { AudioRecorder } from 'domains/AudioRecorder/AudioRecorder';
import { Form } from 'domains/Form/Form';
import { MainPanel } from 'domains/MainPanel/MainPanel';
import { ProfilePage } from 'domains/ProfilePage/ProfilePage';
import paths from 'shared/paths';
import { RouterConfig } from '..';

export const appRoutes: RouterConfig = [
    {
        routes: [
            {
                component: ProfilePage,
                exact: true,
                path: paths.profile,
            },
            {
                component: MainPanel,
                exact: true,
                path: paths.home,
            },
            {
                component: AudioRecorder,
                exact: true,
                path: paths.record,
            },
            {
                component: Form,
                exact: true,
                path: paths.form,
            },
        ],
        isPrivate: true,
        layoutComponent: MainLayout,
    },
];
