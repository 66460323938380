export const palette = {
    alto: '#D9D9D9',
    alto40: 'rgba(217, 217, 217, 0.4)',
    black: '#333435',
    blue: '#009FE3',
    pictonBlue: '#3BC1F9',
    dustyBlue: '#3bc1f940',
    dustyGray: '#979797',
    emperor: '#545454',
    emperor40: '#545454',
    green: '#00C4B9',
    green31: 'rgba(0, 196, 185, 0.31)',
    grey: '#76808F',
    lightGrey: 'rgba(198, 198, 198, 1)',
    iron: '#DDDDE0',
    red: '#F45987',
    shark: '#282C34',
    white: '#FFFFFF',
    yellow: '#FFE27E',
    silver: '#C6C6C6',
    wildSand: '#F5F5F5',
};

export const global = {
    fontSizes: {
        f6: '6px',
        f8: '8px',
        f9: '9px',
        f10: '10px',
        f11: '11px',
        f12: '12px',
        f13: '13px',
        f14: '14px',
        f16: '16px',
        f18: '18px',
        f20: '20px',
        f24: '24px',
        f36: '36px',
    },
    fontWeight: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        black: 900,
    },
    fontFamily: {
        primary: `'JosefinSans', sans-serif`,
        secondary: `'ExconVariable', sans-serif`,
    },
    borderRadius: {
        checkbox: 2,
        default: 5,
        textarea: 15,
        button: 45,
    },
    transitions: {
        default: 0.5,
    },
};
