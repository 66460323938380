import { Header } from 'components/_global/Header/Header';
import { MainLayoutWrapper } from './MainLayout.styled';
import { Menu } from 'components/_global/Menu/Menu';

interface IProps {
    children: any;
}

export const MainLayout: React.FC<IProps> = ({ children }) => {
    return (
        <MainLayoutWrapper>
            <Header />
            <Menu />
            {children}
        </MainLayoutWrapper>
    );
};
