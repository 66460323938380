import { Loader } from 'components/_universal/Loader/Loader';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IAppRoutes } from 'routes/index';
import paths from 'shared/paths';
import { useAuthMeQuery } from 'src/graphql/generated';

interface IProps extends RouteProps {
    allowFor?: IAppRoutes['allowFor'];
    isPrivate?: IAppRoutes['isPrivate'];
    routeType?: IAppRoutes['type'];
}

const CustomRoute: React.FC<IProps> = ({
    allowFor,
    isPrivate,
    routeType,
    ...routeProps
}) => {
    const { error, loading } = useAuthMeQuery();

    const redirectToLogin = !!isPrivate && !!error;

    if (loading) return <Loader />;

    if (redirectToLogin) return <Redirect to={paths.login} />;

    return <Route {...routeProps} />;
};

export default CustomRoute;
