import { useState, useRef, useEffect } from 'react';
import {
    AudioNameContainer,
    AudioRecorderContainer,
    AudioRecorderDescription,
    AudioRecorderInnerContainer,
    AudioRecorderText,
    AudioRecroderInfo,
    ButtonsContainer,
    ButtonsDivider,
    ControlsContainer,
    FileName,
    SendingCompleted,
    SendingCompletedText,
} from './AudioRecorder.styled';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { dateFormat } from 'utils/dateFormat';
import {
    useAuthMeQuery,
    useCreateSessionMutation,
} from 'src/graphql/generated';
import { Button } from 'components/_universal/Button/Button';
import { useCreateRecordingMutation } from 'src/graphql/generated';
import { notify } from 'components/ToastNotification/ToastNotification';
import { ToastTypes } from 'types/CommonTypes';

export const AudioRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSuccessfullySent, setIsSuccessfullySent] = useState(false);
    const [audioData, setAudioData] = useState<Blob | null>(null);
    const [recordingStartTime, setRecordingStartTime] = useState(0);
    const [recordingLength, setRecordingLength] = useState(0);
    const audioPlayerRef = useRef<HTMLAudioElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const [createRecording, { loading }] = useCreateRecordingMutation();
    const [createSession, { data: dataSession }] = useCreateSessionMutation();

    const { data } = useAuthMeQuery();
    const username = data?.me?.name?.replace(/ /g, '_').toLocaleLowerCase();
    const filename = `nagranie_${username}_${dateFormat.recorderDate(
        new Date(),
    )}.mp3`;

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            mediaRecorderRef.current = new MediaRecorder(stream);
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.ondataavailable =
                    handleAudioDataAvailable;
                mediaRecorderRef.current.start();
                setIsRecording(true);
                setRecordingStartTime(Date.now());
            }
        } catch (error) {
            console.error('Error accessing media devices.', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.stream
                .getTracks()
                .forEach((track) => track.stop());
            setIsRecording(false);
            const recordingEndTime = Date.now();
            const lengthInSeconds =
                (recordingEndTime - recordingStartTime) / 1000;
            setRecordingLength(lengthInSeconds);
        }
    };

    const handleAudioDataAvailable = (e: BlobEvent) => {
        setAudioData(e.data);
    };

    const uploadAudioFile = () => {
        if (audioData && dataSession) {
            setIsSuccessfullySent(true);
            const file = new File([audioData], filename, { type: 'audio/mp3' });

            createRecording({
                variables: {
                    file: file,
                    session: {
                        length: recordingLength,
                        uuid: dataSession?.createSession?.uuid,
                    },
                },
                onCompleted: () => {
                    return notify('Dodano nagranie')[ToastTypes.SUCCESS]();
                },
                onError: (error) => {
                    return notify('Błąd dodawania nagrania: ' + error)[
                        ToastTypes.ERROR
                    ]();
                },
            });
        }
    };

    const playAudio = () => {
        if (audioPlayerRef.current) {
            setIsPlaying(true);
            audioPlayerRef.current.play();
            audioPlayerRef.current.addEventListener('ended', () => {
                setIsPlaying(false);
            });
        }
    };

    const stopAudio = () => {
        if (audioPlayerRef.current) {
            setIsPlaying(false);
            audioPlayerRef.current.pause();
        }
    };

    const resetRecording = () => {
        setIsPlaying(false);
        setAudioData(null);
    };

    const resetComponentStatus = () => {
        setAudioData(null);
        setIsPlaying(false);
        setIsSuccessfullySent(false);
    };

    useEffect(() => {
        if (audioData) {
            const audioUrl = URL.createObjectURL(audioData);
            if (audioPlayerRef.current) {
                audioPlayerRef.current.src = audioUrl;
            }
            return () => URL.revokeObjectURL(audioUrl);
        }
    }, [audioData]);

    useEffect(() => {
        createSession();
    }, []);

    return isSuccessfullySent ? (
        <SendingCompleted>
            <IconManager name={'TrophyIcon'} />
            <SendingCompletedText>
                Twój plik został wysłany.
            </SendingCompletedText>
            <Button onPress={() => resetComponentStatus()}>DODAJ WIĘCEJ</Button>
        </SendingCompleted>
    ) : (
        <AudioRecorderContainer>
            <AudioRecorderInnerContainer>
                <ControlsContainer>
                    {!audioData ? (
                        isRecording ? (
                            <IconManager
                                cursorPointer
                                name={'AudioRecorderEndIcon'}
                                onClick={() => stopRecording()}
                            />
                        ) : (
                            <IconManager
                                cursorPointer
                                name={'AudioRecorderIcon'}
                                onClick={() => startRecording()}
                            />
                        )
                    ) : (
                        <IconManager
                            cursorPointer
                            name={'AudioRecorderUploadIcon'}
                            onClick={() => uploadAudioFile()}
                        />
                    )}
                </ControlsContainer>
                <AudioRecorderDescription>
                    {audioData
                        ? 'Wyślij lub ponownie nagraj w razie potrzeby.'
                        : 'Przeczytaj poniższe zdanie.'}
                </AudioRecorderDescription>
                {!audioData && (
                    <AudioRecorderText>
                        {dataSession?.createSession?.text}
                    </AudioRecorderText>
                )}
                {audioData && (
                    <AudioNameContainer>
                        <FileName>{filename}</FileName>
                        <ButtonsContainer>
                            <IconManager
                                name={
                                    isPlaying
                                        ? 'AudioRecorderStopIcon'
                                        : 'AudioRecorderPlayIcon'
                                }
                                onClick={isPlaying ? stopAudio : playAudio}
                                cursorPointer
                                size={20}
                            />
                            <ButtonsDivider />
                            <IconManager
                                name={'AudioRecorderAgainIcon'}
                                onClick={resetRecording}
                                cursorPointer
                            />
                            <audio ref={audioPlayerRef} />
                        </ButtonsContainer>
                    </AudioNameContainer>
                )}
                {isRecording && (
                    <AudioRecroderInfo>Trwa nagrywanie!</AudioRecroderInfo>
                )}
            </AudioRecorderInnerContainer>
        </AudioRecorderContainer>
    );
};
