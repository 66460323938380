import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import FontFilePrimaryTtf from 'assets/fonts/JosefinSansVariableFont_wght.ttf';
import FontFilePrimaryWoff from 'assets/fonts/JosefinSansVariableFont_wght.woff';
import FontFilePrimaryWoff2 from 'assets/fonts/JosefinSansVariableFont_wght.woff2';
import FontFilePrimarySvg from 'assets/fonts/JosefinSansVariableFont_wght.svg';
import FontFilePrimaryEot from 'assets/fonts/JosefinSansVariableFont_wght.eot';

import ExconVariableTtf from 'assets/fonts/Excon-Variable.ttf';
import ExconVariableWoff from 'assets/fonts/Excon-Variable.woff';
import ExconVariableWoff2 from 'assets/fonts/Excon-Variable.woff2';
import ExconVariableEot from 'assets/fonts/Excon-Variable.eot';

export const GlobalStyled = createGlobalStyle<{ lockLayout?: boolean }>`
    ${normalize};

    @font-face {
        font-family: 'JosefinSans';
        src: url(${FontFilePrimaryEot});
        src: url(${FontFilePrimaryEot}) format('embedded-opentype'),
            url(${FontFilePrimaryWoff2}) format('woff2'),
            url(${FontFilePrimaryWoff}) format('woff'),
            url(${FontFilePrimaryTtf}) format('truetype'),
            url(${FontFilePrimarySvg}) format('svg');
        font-display: swap;
        font-style: normal;
    }

    @font-face {
        font-family: 'ExconVariable';
        src: url(${ExconVariableEot}) format('embedded-opentype'),
            url(${ExconVariableWoff2}) format('woff2'),
            url(${ExconVariableWoff}) format('woff'),
            url(${ExconVariableTtf}) format('truetype');
        font-display: swap;
        font-style: normal;
    }
    
    * {
        box-sizing: border-box;
    }

    html {
        height: 100%;
        ${({ lockLayout }) => lockLayout && 'overflow-y: hidden;'}
    }
    
    body {
        height: 100%;
        margin: 0;
        font-family: 'JosefinSans', 'ExconVariable', 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    #root {
        height: 100%;
    }
    
    code {
        font-family: 'Lato', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }
    
    svg {
      transition: all .5s;
      path, circle, rect {
        transition: all .5s ;
      }
    }
`;
