import { Button } from 'components/_universal/Button/Button';
import { Link } from 'react-router-dom';
import {
    DefaultText,
    SubTitle,
    Title,
} from 'components/_universal/Typography.styled';
import { TextInput } from 'components/_form-elements/TextInput/TextInput';
import styled, { css } from 'styled-components';

export const LoginPageWrapper = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        background-color: ${colors.app_bg};
        color: ${colors.text};
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
    `,
);

export const LoginPageBox = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.app_bg};
        border-radius: 35px;
        box-shadow: 0px 0px 15px rgba(84, 84, 84, 0.3);
        padding: 77px 148px 46px;
    `,
);

export const FormTitle = styled(SubTitle)`
    margin-bottom: 90px;
`;

export const FromSubTitle = styled(Title)`
    font-weight: 400;
    margin-bottom: 64px;
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 354px;
    width: 100%;
`;

export const ForgotPasswordText = styled(Link)(
    ({ theme: { fontSizes, colors, fontFamily } }) => css`
        color: ${colors.text}65;
        display: flex;
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f12};
        justify-content: flex-end;
        line-height: 30px;
        text-decoration: none;
    `,
);

export const StyledInput = styled(TextInput)``;

export const StyledLoginButton = styled(Button)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.f20};
        margin: 18px 0;
    `,
);

export const StyledGoogleButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin: 18px 0;
    padding: 10px 32px;
`;

export const AnotherLoginMethodText = styled(DefaultText)(
    ({ theme: { colors, fontFamily } }) =>
        css`
            color: ${colors.text}65;
            display: flex;
            font-family: ${fontFamily.secondary};
            justify-content: center;
            margin: 0;
            position: relative;
            width: fit-content;
            align-self: center;

            &:before,
            &:after {
                background-color: ${colors.textLine};
                content: '';
                height: 1px;
                position: absolute;
                top: 7px;
                width: 36%;
            }
            &:after {
                right: -40%;
            }
            &:before {
                left: -40%;
            }
        `,
);
