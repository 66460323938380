import { Modal } from 'components/Modal/Modal';
import { notify } from 'components/ToastNotification/ToastNotification';
import { Button } from 'components/_universal/Button/Button';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Loader } from 'components/_universal/Loader/Loader';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LocalStorageManager from 'services/LocalStorageManager/LocalStorageManager';
import paths from 'shared/paths';
import {
    useAuthMeQuery,
    useListTranscriptsQuery,
    useMarkTranscriptVerifiedMutation,
} from 'src/graphql/generated';
import {
    selectRecordingInfo,
    setIsTrascriptionModal,
    setRecordingInfo,
} from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { ToastTypes } from 'types/CommonTypes';
import { TranscryptionVerificationBox } from '../TranscryptionVerificationBox/TranscryptionVerificationBox';
import {
    HeaderCloseWrapper,
    ListWrapper,
    MarkButtonWrapper,
    ModalFooterWrapper,
    ModalHeaderWrapper,
    ModalSubTitle,
    ModalTitle,
} from './TranscryptionVerificationModal.styled';

export const TranscryptionVerificationModal = () => {
    const history = useHistory();
    const selectedRecordingInfo = useSelector(selectRecordingInfo);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
    const [markTranscriptVerifiedMutation] =
        useMarkTranscriptVerifiedMutation();
    const { data: dataMe } = useAuthMeQuery();

    const checkedThisUser =
        selectedRecordingInfo?.wholeTranscriptVerifiedBy?.id ===
            dataMe?.me?.id ||
        selectedRecordingInfo?.wholeTranscriptVerifiedBy?.id === undefined;

    const handleAccept = () => {
        markTranscriptVerifiedMutation({
            variables: {
                recordingId: selectedRecordingInfo.id,
                verified: true,
            },
            onCompleted: () => {
                onClose();
            },
            onError: (error) => {
                return notify('Coś poszło nie tak: ' + error.message)[
                    ToastTypes.ERROR
                ]();
            },
        });
    };

    const handleReject = () => {
        markTranscriptVerifiedMutation({
            variables: {
                recordingId: selectedRecordingInfo.id,
                verified: false,
            },
            onCompleted: () => {
                onClose();
            },
            onError: (error) => {
                return notify('Coś poszło nie tak: ' + error.message)[
                    ToastTypes.ERROR
                ]();
            },
        });
    };

    const { data, loading } = useListTranscriptsQuery({
        variables: {
            recordingId: selectedRecordingInfo.id,
            skip: (currentPage - 1) * itemsPerPage,
            sortBy: 'id',
            sortOrder: 'asc',
            take: itemsPerPage,
        },
        onError: (error) => {
            if (error.message === 'Session not found') {
                history.push(paths.login);
                LocalStorageManager.clear();
            }
        },
    });

    const totalPages =
        (data?.listTranscripts?.total &&
            Math.ceil(data.listTranscripts.total / itemsPerPage)) ||
        0;

    const onClose = () => {
        dispatch(setRecordingInfo(null));
        dispatch(setIsTrascriptionModal(null));
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Modal>
            <ModalHeaderWrapper>
                {/* <HeaderJsonButton>otwórz json</HeaderJsonButton> */}
                <HeaderCloseWrapper onClick={() => onClose()}>
                    <IconManager cursorPointer name={'Close'} size={30} />
                </HeaderCloseWrapper>
                <ModalTitle>weryfikacja transkrypcji</ModalTitle>
                <ModalSubTitle>{selectedRecordingInfo?.name}</ModalSubTitle>
            </ModalHeaderWrapper>
            <ListWrapper>
                {loading ? (
                    <Loader />
                ) : (
                    data?.listTranscripts?.data?.map((item, index) => (
                        <TranscryptionVerificationBox
                            end={item?.end}
                            index={index + 1}
                            key={index}
                            lowQuality={item?.lowQuality}
                            person={item?.author}
                            start={item?.start}
                            text={item?.text}
                            transcriptId={item?.id}
                        />
                    ))
                )}
            </ListWrapper>
            {checkedThisUser && currentPage === totalPages && (
                <MarkButtonWrapper>
                    <Button onPress={handleAccept}>
                        Akceptuj transkrypcję
                    </Button>
                    <Button buttonType={'secondary'} onPress={handleReject}>
                        Odrzuć transkrypcję
                    </Button>
                </MarkButtonWrapper>
            )}
            {totalPages > 1 && (
                <ModalFooterWrapper>
                    <Button
                        disabled={currentPage === 1}
                        onPress={handlePreviousPage}
                    >
                        Poprzednia strona
                    </Button>
                    <div>
                        Strona {currentPage} z {totalPages}
                    </div>
                    <Button
                        disabled={totalPages === currentPage}
                        onPress={handleNextPage}
                    >
                        Następna strona
                    </Button>
                </ModalFooterWrapper>
            )}
        </Modal>
    );
};
