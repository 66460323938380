import { AnyAction, configureStore, combineReducers } from '@reduxjs/toolkit';
import { listenerMiddleware } from 'state/redux/middlewares/listeners';
import { app } from 'state/redux/slices/app';
import { getCommonStateData } from 'utils/getCommonStateData';

export const appReducer = combineReducers({
    app,
});

// Reset store after logout
const reducerProxy = (
    state: ReturnType<typeof appReducer> | undefined,
    action: AnyAction,
) =>
    action.type === 'auth/logout/fulfilled'
        ? appReducer(undefined, action)
        : appReducer(state, action);

const store = configureStore({
    reducer: reducerProxy,
    devTools: process.env.REACT_APP_ENVIRONMENT !== 'prod',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: { extraArgument: { getCommonStateData } },
        })
            // NOTE: Since this can receive actions with functions inside,
            // it should go before the serializable check middleware
            .prepend(listenerMiddleware.middleware),
});

// Named exports to avoid circular dependency in middleware
export const { dispatch, getState } = store;
export default store;
