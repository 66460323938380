import React from 'react';
import { StyledInput, SectionText, SectionSubtitle } from '../../Form.styled';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import {
    SymptomsWrapper,
    BlueBackground,
    RemoveButtonWrapper,
    SymptomsInnerWrapper,
} from '../Styles';
import { SurgeryHospitalizationsFields } from 'domains/Form/types';

interface Props {
    index: number;
    isEdit: boolean;
    onChange: (index: number, field: string, value: string) => void;
    onRemove: (index: number) => void;
    surgery: SurgeryHospitalizationsFields;
}

const NewSurgeryHospitalization: React.FC<Props> = ({
    index,
    surgery,
    onChange,
    onRemove,
    isEdit,
}) => {
    return (
        <SymptomsWrapper isEdit={isEdit}>
            <BlueBackground>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>
                        Dodaj operację/hospitalizację
                    </SectionSubtitle>
                    {isEdit ? (
                        <StyledInput
                            defaultValue={surgery.description}
                            onChange={(e) =>
                                onChange(index, 'description', e.target.value)
                            }
                        />
                    ) : (
                        <SectionText>{surgery.description}</SectionText>
                    )}
                </SymptomsInnerWrapper>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Dodaj opis</SectionSubtitle>
                    {isEdit ? (
                        <StyledInput
                            defaultValue={surgery.cause}
                            onChange={(e) =>
                                onChange(index, 'cause', e.target.value)
                            }
                        />
                    ) : (
                        <SectionText>{surgery.cause}</SectionText>
                    )}
                </SymptomsInnerWrapper>
            </BlueBackground>
            <SymptomsInnerWrapper>
                <SectionSubtitle>Data / czas trwania:</SectionSubtitle>
                {isEdit ? (
                    <StyledInput
                        defaultValue={surgery.duration}
                        onChange={(e) =>
                            onChange(index, 'duration', e.target.value)
                        }
                    />
                ) : (
                    <SectionText>{surgery.duration}</SectionText>
                )}
            </SymptomsInnerWrapper>

            {isEdit && (
                <RemoveButtonWrapper>
                    <IconManager
                        onClick={() => onRemove(index)}
                        name={'TrashIcon'}
                    />
                </RemoveButtonWrapper>
            )}
        </SymptomsWrapper>
    );
};

export default NewSurgeryHospitalization;
