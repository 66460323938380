import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
    Control,
    FieldError,
    FieldErrors,
    useController,
} from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import {
    TextareaInput,
    TextareaSendIconWrapper,
    TextareaWrapper,
} from './TextareaComment.styled';

export interface TextareaCommentProps {
    control: Control<any, any>;
    defaultValue?: string;
    errors?: FieldError | FieldErrors;
    name: string;
    onClick: () => void;
}

export const TextareaComment = ({
    control,
    defaultValue,
    errors,
    name,
    onClick,
}: TextareaCommentProps) => {
    const [textareaCommentEditable, setTextareaCommentEditable] =
        useState(false);
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });

    return (
        <>
            <TextareaWrapper isError={!!errors?.message}>
                <TextareaInput
                    onChange={onChange}
                    value={value}
                    defaultValue={defaultValue}
                    id={name}
                    name={name}
                    placeholder={'dodaj komentarz*'}
                />
                <TextareaSendIconWrapper
                    onClick={() => {
                        setTextareaCommentEditable(!textareaCommentEditable);
                        onClick();
                    }}
                >
                    <IconManager
                        cursorPointer
                        fill={textareaCommentEditable ? ['text'] : ['primary']}
                        name={'TextAreaSendIcon'}
                        size={22}
                    />
                </TextareaSendIconWrapper>
            </TextareaWrapper>

            {!!errors?.message && (
                <Fade direction="up" triggerOnce>
                    <ErrorMessage error={errors} />
                </Fade>
            )}
        </>
    );
};
