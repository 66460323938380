import { notify } from 'components/ToastNotification/ToastNotification';
import Select from 'components/_form-elements/Select/Select';
import { Textarea } from 'components/_form-elements/Textarea/Textarea';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalStorageManager from 'services/LocalStorageManager/LocalStorageManager';
import paths from 'shared/paths';
import {
    useGetTranscriptUrlLazyQuery,
    useUpdateTranscriptMutation,
} from 'src/graphql/generated';
import { ToastTypes } from 'types/CommonTypes';
import {
    BadQualityItem,
    BadQualityWrapper,
    BoxNumber,
    SelectsWrapper,
    Wrapper,
} from './TranscryptionVerificationBox.styled';

export interface IProps {
    end: number;
    transcriptId: number;
    index: number;
    lowQuality: boolean;
    person: string;
    start: number;
    text: string;
}

export const TranscryptionVerificationBox = ({
    end,
    transcriptId,
    index,
    lowQuality,
    person,
    start,
    text,
}: IProps) => {
    const history = useHistory();
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [isLowQuality, setIsLowQuality] = useState(lowQuality);
    const [audioSource, setAudioSource] = useState<string>('');
    const [updateTranscriptMutation] = useUpdateTranscriptMutation();
    const [getTranscriptUrl] = useGetTranscriptUrlLazyQuery({
        onError: (error) => {
            if (error.message === 'Session not found') {
                history.push(paths.login);
                LocalStorageManager.clear();
            }
        },
    });

    const handleClickChange = async () => {
        try {
            await updateTranscriptMutation({
                variables: {
                    input: {
                        transcriptId: transcriptId,
                        lowQuality: !isLowQuality,
                    },
                },
                onCompleted: () => {
                    setIsLowQuality(!isLowQuality);
                    return notify('Pomyślnie zaktualizowano')[
                        ToastTypes.SUCCESS
                    ]();
                },
                onError: (error) => {
                    return notify('Coś poszło nie tak: ' + error.message)[
                        ToastTypes.ERROR
                    ]();
                },
                refetchQueries: 'active',
            });
        } catch (error) {
            console.error('Mutation error:', error);
        }
    };

    const pauseAudio = () => {
        const audioElement = audioRef?.current;

        if (audioElement) {
            audioElement?.pause();
            setIsAudioPlaying(false);
        }
    };

    const playAudio = () => {
        if (audioSource) {
            const audioElement = audioRef?.current;

            if (audioElement) {
                audioElement
                    .play()
                    .then(() => setIsAudioPlaying(true))
                    .catch((error) =>
                        console.error('Error playing audio: ', error),
                    );
            }
        } else {
            fetchAudioSource();
        }
    };

    const fetchAudioSource = async () => {
        const { data } = await getTranscriptUrl({
            variables: { id: transcriptId },
        });

        if (data && data.getTranscriptUrl) {
            setAudioSource(data.getTranscriptUrl);
            const audioElement = audioRef?.current;

            if (audioElement) {
                audioElement.src = data.getTranscriptUrl;
                audioElement
                    .play()
                    .then(() => setIsAudioPlaying(true))
                    .catch((error) =>
                        console.error('Error playing audio: ', error),
                    );
            }
        }
    };

    useEffect(() => {
        const audioElement = audioRef?.current;

        if (audioElement) {
            audioElement?.addEventListener('ended', () => {
                setIsAudioPlaying(false);
            });

            return () => {
                audioElement?.removeEventListener('ended', () => {
                    setIsAudioPlaying(false);
                });
            };
        }
    }, []);

    return (
        <Wrapper>
            <BoxNumber>{index}.</BoxNumber>

            <audio style={{ display: 'none' }} ref={audioRef}>
                <source src={audioSource} />
                Twoja przeglądarka nie obsługuje odtwarzacza audio.
            </audio>

            <IconManager
                cursorPointer
                name={isAudioPlaying ? 'PauseIcon60' : 'PlayIcon'}
                onClick={
                    isAudioPlaying ? () => pauseAudio() : () => playAudio()
                }
            />
            <Textarea
                defaultValue={text}
                end={end}
                name="test"
                start={start}
                transcriptId={transcriptId}
            />
            <SelectsWrapper>
                <Select defaultValue={person} transcriptId={transcriptId} />
                <BadQualityWrapper onClick={() => handleClickChange()}>
                    <BadQualityItem isLowQuality={isLowQuality}>
                        {isLowQuality && (
                            <IconManager
                                cursorPointer
                                name={'BadQualityIcon'}
                            />
                        )}
                    </BadQualityItem>
                    zła jakość
                </BadQualityWrapper>
            </SelectsWrapper>
        </Wrapper>
    );
};
