import styled, { css } from 'styled-components';
import RecorderBG from 'assets/images/illustration_AI_Voice.svg';

export const AudioRecorderContainer = styled.div`
    align-items: center;
    background-image: url(${RecorderBG});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    position: relative;
    width: 100%;
`;

export const AudioRecorderInnerContainer = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        border-radius: 15px;
        border: 2px solid ${colors.primary};
        background-color: ${colors.app_bg};
        display: flex;
        flex-direction: column;
        height: 70%;
        justify-content: center;
        margin-top: 300px;
        padding: 24px 34px;
        width: 70%;
    `,
);

export const AudioRecorderDescription = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, fontFamily } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.light};
        line-height: 22px;
        text-align: center;
        margin-top: 50px;
    `,
);

export const AudioRecorderText = styled.p(
    ({ theme: { fontSizes, fontWeight, fontFamily } }) => css`
        color: rgba(71, 81, 82, 0.87);
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f36};
        font-weight: ${fontWeight.regular};
        line-height: normal;
        text-align: center;
        margin: 32px 0;
    `,
);

export const AudioRecroderInfo = styled.p(
    ({ theme: { colors, fontFamily } }) => css`
        color: ${colors.secondary};
        text-align: center;
        font-family: ${fontFamily.secondary};
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    `,
);

export const ControlsContainer = styled.div`
    position: absolute;
    top: 25%;
`;

export const AudioNameContainer = styled.div`
    align-items: center;
    border-radius: 45px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    margin: 52px 0;
    padding: 8px;
`;

export const FileName = styled.span(
    ({ theme: { colors, fontFamily } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding: 0 32px;
        text-align: center;
    `,
);

export const ButtonsContainer = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        background-color: ${colors.primary};
        border-radius: 18px;
        display: flex;
        gap: 8px;
        justify-content: center;
        padding: 8px 12px;
    `,
);

export const ButtonsDivider = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.app_bg};
        height: 20px;
        opacity: 0.5;
        width: 1px;
    `,
);

export const SendingCompleted = styled.div`
    align-items: center;
    background-image: url(${RecorderBG});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    width: 100%;
`;

export const SendingCompletedText = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, fontFamily } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.light};
        line-height: 22px;
        margin: 22px 0;
        text-align: center;
    `,
);
