import { customStyles } from 'components/_form-elements/Select/Select.styled';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { FamilyHistoryFields } from 'domains/Form/types';
import Select, { SingleValue } from 'react-select';
import { SectionSubtitle, StyledInput } from '../../Form.styled';
import {
    BlueBackground,
    RemoveButtonWrapper,
    SymptomsInnerWrapper,
    SymptomsWrapper,
} from '../Styles';

interface NewFamilyHistoryProps {
    index: number;
    family: FamilyHistoryFields;
    onChange: (index: number, field: string, value: any) => void;
    onRemove: (index: number) => void;
    isEdit: boolean;
}

export const relationshipOptions = [
    { value: 'FIRST_DEGREE', label: 'Pierwszy stopień' },
    { value: 'SECOND_DEGREE', label: 'Drugi stopień' },
];

export const NewFamilyHistory = ({
    index,
    family,
    onChange,
    onRemove,
    isEdit,
}: NewFamilyHistoryProps) => {
    const handleRelationshipChange = (
        newValue: SingleValue<{ value: string }>,
    ) => {
        onChange(index, 'relationship', newValue ? newValue.value : '');
    };

    return (
        <SymptomsWrapper isEdit={isEdit}>
            <BlueBackground>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Dodaj dolegliwość</SectionSubtitle>
                    <StyledInput
                        defaultValue={family.condition}
                        onChange={(e) =>
                            onChange(index, 'condition', e.target.value)
                        }
                        placeholder="Nazwa choroby"
                    />
                </SymptomsInnerWrapper>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Dodaj opis</SectionSubtitle>
                    <StyledInput
                        defaultValue={family.description}
                        onChange={(e) =>
                            onChange(index, 'description', e.target.value)
                        }
                        placeholder="Opis"
                    />
                </SymptomsInnerWrapper>
            </BlueBackground>
            <SymptomsInnerWrapper>
                <SectionSubtitle>Stopień pokrewieństwa:</SectionSubtitle>
                <Select
                    name={`familyHistory[${index}].relationship`}
                    styles={customStyles}
                    options={relationshipOptions}
                    value={relationshipOptions.find(
                        (option) => option.value === family.relationship,
                    )}
                    onChange={handleRelationshipChange}
                />
            </SymptomsInnerWrapper>

            {isEdit && (
                <RemoveButtonWrapper>
                    <IconManager
                        onClick={() => onRemove(index)}
                        name={'TrashIcon'}
                    />
                </RemoveButtonWrapper>
            )}
        </SymptomsWrapper>
    );
};
