import { ErrorIcon } from 'shared/svgs/Icons';
import { ErrorMessageWrapper, ErrorText } from './ErrorMessage.styled';

interface ErrorMessageProps {
    error?: any;
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
    return (
        <ErrorMessageWrapper>
            <ErrorIcon width="22" height="22" />
            <ErrorText>{error?.message}</ErrorText>
        </ErrorMessageWrapper>
    );
};
