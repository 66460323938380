import * as Icons from 'shared/svgs/Icons';
import defaultTheme from 'shared/theme/default.theme';

export enum EnvType {
    development = 'development',
    production = 'production',
}

export enum Theme {
    DEFAULT = 'DEFAULT',
    DARK = 'DARK',
}

export enum USER_TYPE {
    JUNIOR = 'JUNIOR',
    SENIOR = 'SENIOR',
}

export type ThemeType = typeof defaultTheme;
export type ColorType = keyof ThemeType['colors'];
export type FontWeight = keyof ThemeType['fontWeight'];
export type FontSizes = keyof ThemeType['fontSizes'];

export type IconName = keyof typeof Icons;

export type ButtonTypes = 'primary' | 'secondary' | 'grey';

export type TextareaTypes = 'primary' | 'secondary';

export type FieldTypes = 'text' | 'email' | 'number' | 'password';

export enum ToastTypes {
    DEFAULT = 'default',
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export enum EventType {
    RECORDING_CREATED = 'recording-created',
    RECORDING_VERIFIED = 'recording-verified',
    TRANSCRIPT_REQUESTED = 'transcript-requested',
    TRANSCRIPT_VERIFIED = 'transcript-verified',
    TRANSCRIPT_GENERATED = 'transcript-generated',
    REPORT_REQUESTED = 'report-requested',
    REPORT_GENERATED = 'report-generated',
}

export interface EventMessage {
    type: EventType;
    message: { recordingId: number; metadata?: Record<string, unknown> };
}
