import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastNotification } from 'components/ToastNotification/ToastNotification';
import { GlobalStyled } from 'components/_global/Global.styled';
import { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Wrapper } from 'src/App/App.styled';
import { ThemeContext } from 'src/App/ThemeContext';
import client from 'src/boot/apolloClient';
import RootRoutes from 'src/routes/RootRoutes';
import store from 'state/redux/store';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'types/CommonTypes';
import { resolveEnv } from 'utils/helpers';
import themeVariant from 'utils/themeVariant';

export const App = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const clientId =
        '70184062420-tfuat1r5v01l1en7mpj19j3eqdhq8l4h.apps.googleusercontent.com';

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <ThemeContext.Provider
                value={{
                    modalIsOpen,
                    setModalIsOpen,
                }}
            >
                <Provider store={store}>
                    <BrowserRouter basename={resolveEnv('PUBLIC_URL')}>
                        <ApolloProvider client={client}>
                            <ThemeProvider theme={themeVariant[Theme.DEFAULT]}>
                                <GlobalStyled lockLayout={modalIsOpen} />
                                <Wrapper>
                                    <ToastNotification />
                                    <RootRoutes />
                                </Wrapper>
                            </ThemeProvider>
                        </ApolloProvider>
                    </BrowserRouter>
                </Provider>
            </ThemeContext.Provider>
        </GoogleOAuthProvider>
    );
};
