import styled, { css } from 'styled-components';
import { SubTitle, Title } from 'components/_universal/Typography.styled';

export const ModalHeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 30px 33px;
`;

export const ModalTitle = styled(Title)(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.text};
            font-size: ${fontSizes.f24};
            font-weight: ${fontWeight.light};
            text-transform: uppercase;
        `,
);

export const ModalSubTitle = styled(SubTitle)(
    ({ theme: { colors, fontSizes } }) =>
        css`
            color: ${colors.text};
            font-size: ${fontSizes.f16};
        `,
);

export const HeaderJsonButton = styled.div(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
        css`
            background: ${colors.white};
            border-radius: 25px;
            border: 2px solid ${colors.primary};
            color: ${colors.primary};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f12};
            font-weight: ${fontWeight.regular};
            left: 33px;
            padding: 8px 16px;
            position: absolute;
            text-transform: uppercase;
            top: 30px;
        `,
);

export const HeaderCloseWrapper = styled.div`
    position: absolute;
    top: 30px;
    right: 33px;
`;

export const ModalFooterWrapper = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        border-top: 1px solid ${colors.profileBorder};
        display: flex;
        gap: 20px;
        justify-content: space-between;
        position: relative;
        width: 100%;
        padding: 20px;
    `,
);

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: scroll;
    padding: 16px 32px;
    width: 100%;
`;

export const MarkButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
`;
