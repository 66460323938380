import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

interface IProps {
    size?: number;
}

export const Loader = ({ size }: IProps) => {
    return (
        <LoaderWrapper>
            <Oval
                height={size ? size : 100}
                width={size ? size : 100}
                color="#00C4B9"
                visible={true}
                ariaLabel="loading-indicator"
                secondaryColor="#00C4B9"
                strokeWidth={4}
                strokeWidthSecondary={3}
            />
        </LoaderWrapper>
    );
};

export const LoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
