import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalStorageManager from 'services/LocalStorageManager/LocalStorageManager';
import paths from 'shared/paths';
import { useAuthMeLazyQuery, useLogoutMutation } from 'src/graphql/generated';
import { useOnClickOutside } from 'utils/hooks';
import {
    ProfileDropdownListItem,
    ProfileDropdownListWrapper,
    ProfileDropdownLogutItem,
    ProfileDropdownName,
    ProfileDropdownTextWrapper,
    ProfileDropdownTitle,
    ProfileDropdownWrapper,
    ProfileImage,
} from './ProfileDropdown.styled';

export const ProfileDropdown = () => {
    const [isProfileDropDownOpen, setIsProfileDropDownOpen] = useState(false);
    const history = useHistory();
    const ref = useRef<HTMLDivElement>(null);

    const [logOut] = useLogoutMutation();
    const [getAuthMe, { data }] = useAuthMeLazyQuery({
        onError: (error) => {
            if (error.message === 'Session not found') {
                history.push(paths.login);
                LocalStorageManager.clear();
            }
        },
    });
    const user = data?.me;

    const onClose = () => {
        setIsProfileDropDownOpen(false);
    };

    const onLogOut = () => {
        logOut({
            onCompleted: () => {
                history.push(paths.login);
                LocalStorageManager.clear();
            },
        });
    };

    useOnClickOutside(ref, onClose);

    useEffect(() => {
        getAuthMe();
    }, []);

    return (
        <ProfileDropdownWrapper
            onClick={() => setIsProfileDropDownOpen(!isProfileDropDownOpen)}
            ref={ref}
        >
            {user?.avatar && <ProfileImage src={user?.avatar} />}
            <ProfileDropdownTextWrapper>
                <ProfileDropdownName>{user?.name}</ProfileDropdownName>
                <ProfileDropdownTitle>{user?.role}</ProfileDropdownTitle>
            </ProfileDropdownTextWrapper>
            <IconManager
                cursorPointer
                name={'ArrowWhite'}
                rotate={isProfileDropDownOpen ? 180 : 0}
            />
            {isProfileDropDownOpen && (
                <ProfileDropdownListWrapper>
                    <ProfileDropdownListItem to={paths.profile}>
                        <IconManager
                            cursorPointer
                            name={'ProfileIcon'}
                            size={20}
                        />
                        profil
                    </ProfileDropdownListItem>
                    <ProfileDropdownListItem to={paths.home}>
                        <IconManager
                            cursorPointer
                            name={'MainPanelIcon'}
                            size={20}
                        />
                        panel główny
                    </ProfileDropdownListItem>
                    {/* <ProfileDropdownListItem to={paths.record}>
                        <IconManager
                            cursorPointer
                            name={'RecordIcon'}
                            size={20}
                        />
                        nagraj
                    </ProfileDropdownListItem> */}
                    <ProfileDropdownLogutItem onClick={() => onLogOut()}>
                        <IconManager
                            cursorPointer
                            name={'LogoutIcon'}
                            size={20}
                        />
                        wyloguj się
                    </ProfileDropdownLogutItem>
                </ProfileDropdownListWrapper>
            )}
        </ProfileDropdownWrapper>
    );
};
