import { AudioPlayerModal } from 'domains/MainPanel/components/AudioPlayer/AudioPlayerModal';
import { useSelector } from 'react-redux';
import {
    selectAudioUrl,
    selectFormData,
    selectIsTranscriptModal,
} from 'state/redux/slices/app';
import { MainPanelWrapper } from './MainPanel.styled';
import { columns } from './columns';
import { Table } from './components/Table/Table';
import { TranscryptionVerificationModal } from './components/TranscryptionVerificationModal/TranscryptionVerificationModal';
import { Form } from 'domains/Form/Form';

export const MainPanel = () => {
    const selectedAudioUrl = useSelector(selectAudioUrl);
    const selectedFormData = useSelector(selectFormData);
    const selectedIsTranscriptModal = useSelector(selectIsTranscriptModal);

    return (
        <MainPanelWrapper>
            <Table columns={columns} />
            {selectedAudioUrl && <AudioPlayerModal />}
            {selectedIsTranscriptModal && <TranscryptionVerificationModal />}
            {selectedFormData && <Form />}
        </MainPanelWrapper>
    );
};
